import STUtils from "../../assets/styles/utility.module.scss";
import STResetPasswordForm from "../../assets/styles/organisms/resetPasswordForm.module.scss";
import { useState } from "react";
import Button from "../atoms/Button";
import STButton from "../../assets/styles/atoms/button.module.scss";
import TApiResult from "../../types/api/TApiResult";
import Password from "../atoms/form/Password";
import { postPasswordUpdate } from "../../utils/api/postPasswordUpdate";
import AlartMessageBox from "../atoms/AlartMessageBox";
import CONSTANTS from "../../utils/constants";

type Props = {
  email: string;
  setIsApplied: (isApplied: boolean) => void;
};

type Form = {
  password: string;
  passwordConfirm: string;
};

const ResetPasswordForm: React.FC<Props> = (props: Props) => {
  const initial: Form = { password: "", passwordConfirm: "" };

  const [form, setForm] = useState<Form>(initial);
  const [canApply, setCanApply] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const hundleInput = (newForm: Form) => {
    const newIsValidConfirm =
      newForm.password !== "" &&
      newForm.password === newForm.passwordConfirm &&
      CONSTANTS.PATTERN.PASSWORD.test(newForm.password) &&
      newForm.password.length <= CONSTANTS.MAX_LENGTH.PASSWORD;
    setCanApply(newIsValidConfirm);
    setForm(newForm);
  };

  const hundleSubmit = (): void => {
    if (form.password !== form.passwordConfirm) return;
    postPasswordUpdate(props.email, form.password).then((res: TApiResult) => {
      if (!res.isSuccess) return setErrorMessage(res.message);
      props.setIsApplied(true);
    });
  };

  return (
    <div className={STUtils.container_form}>
      <h1 className={STResetPasswordForm.title}>パスワード変更</h1>
      {errorMessage && (
        <div className={STResetPasswordForm.error_message}>{errorMessage}</div>
      )}

      <table className={STResetPasswordForm.table}>
        <tbody>
          <tr>
            <th>
              変更する
              <br className={STUtils.dn_sp} />
              パスワード
            </th>
            <td>
              <Password
                className={STResetPasswordForm.input_password}
                name="password"
                placeholder="パスワード8桁以上の英数字"
                onChange={(input) =>
                  hundleInput({
                    password: input,
                    passwordConfirm: form.passwordConfirm,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <th>
              パスワード
              <br className={STUtils.dn_sp} />
              （確認）
            </th>
            <td>
              <Password
                className={STResetPasswordForm.input_password}
                name="password"
                placeholder="パスワード再入力"
                onChange={(input) =>
                  hundleInput({
                    password: form.password,
                    passwordConfirm: input,
                  })
                }
              />
              {form.password !== form.passwordConfirm && (
                <AlartMessageBox message="パスワードが一致しません" />
              )}
              {form.password !== "" &&
                !CONSTANTS.PATTERN.PASSWORD.test(form.password) && (
                  <AlartMessageBox message="8文字以上の半角英数字記号で入力してください" />
                )}
              {form.password !== "" &&
                (form.password.length > CONSTANTS.MAX_LENGTH.PASSWORD) && (
                  <AlartMessageBox message="20文字以下の半角英数字記号で入力してください" />
                )}
            </td>
          </tr>
        </tbody>
      </table>
      <div className={STResetPasswordForm.from_btn}>
        <Button
          className={`${STButton.primary} ${
            !canApply
              && STButton.disable
          }`}
          clickAction={hundleSubmit}
          text="変更"
          disabled={!canApply}
        />
      </div>
    </div>
  );
};

export default ResetPasswordForm;
