import { Modal, Button, makeStyles, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useContext, useState } from "react";
import STLoginForm from "../../assets/styles/organisms/loginForm.module.scss";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { postNotificationPushMemberRequest } from "../../utils/api/postPushNotificationPushMember";
import { NotificationStatus, getNotificationPermissionAndToken } from "../../hooks/useFireBaseCloudMessage";
import { SiteContext } from "../../providers/SiteProvider";
import { nanoid } from "nanoid";
import { usePushNotificationPopup } from "../../hooks/usePushNotificationPopup";
import { LoginUserContext } from "../../providers/LoginUserProvider";
import { useOS } from "../../hooks/useOS";
import { useIsMobile } from "../../hooks/useIsMobile";

type PushNotificationPopupProps = {
  onClose: any;
  isPushEnabled: boolean;
};

const useStyles = makeStyles(() => ({
  install_container: {
    padding: "12px",
    gap: 12,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    display: "flex",
    gap: 4,
    fontSize: "16px",
    fontWeigth: 600,
  },
  content: {
    marginLeft: 12,
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
  },
  contentsub: {
    marginLeft: 12,
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  footerGroupButton: {
    display: "flex",
    gap: 12,
  },
  iconRequestPermission: {
    color: "var(--main-color)",
  },
  notificationImage: {
    width: "90%",
    height: "auto",
    display: "block",
    margin: "0 auto",
    borderRadius: 10,
    border: "1px solid #ccc",
  },
  button: {
    width: "96px",
    height: 32,
    textAlign: "center",
    fontFamily: "Noto Sans JP",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "100%",
    borderRadius: "90px",
  },
  nextButton: {
    background: "var(--main-color)",
    color: "white",
    "&:hover": {
      opacity: "0.8 !important",
      background: "var(--main-color)",
    },
  },
  closeButton: {
    border: "2px solid var(--main-color)",
    color: "var(--main-color)",
  },
}));

const LIB_DEVICE_ID_KEY = "LibDeviceId";
export const getDeviceId = () => {
  let deviceId = localStorage.getItem(LIB_DEVICE_ID_KEY) || "";
  if (!deviceId) {
    deviceId = nanoid();
    localStorage.setItem(LIB_DEVICE_ID_KEY, deviceId);
  }
  return deviceId;
};

export const LIB_STORAGE_PUSH_NOTIFICATION_KEY = "LibNotificationPermission";
export const LIB_STORAGE_PUSH_NOTIFICATION_SHOW_LATER_KEY =
  "LibNotificationShowLater";

const PushNotificationModal: React.FC<PushNotificationPopupProps> = ({
  onClose,
  isPushEnabled,
}) => {
  const classnames = useStyles();
  const siteContext = useContext(SiteContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { contractCompanyId } = siteContext;
  const loginContext = useContext(LoginUserContext);
  const { loginUser } = loginContext ?? {};
  const { email = "" } = loginUser ?? {};
  const { opened, handleClosePopup } = usePushNotificationPopup(isPushEnabled);
  const { isIOS, isAndroid } = useOS();
  const { isMobile } = useIsMobile();

  const handleClose = () => {
    onClose();
    handleClosePopup();
  };


  const handleContinue = async () => {
    setLoading(true);
    const token = await getNotificationPermissionAndToken() || '';
    const acceptPermission = Notification.permission === NotificationStatus.GRANTED;
    const uid = getDeviceId();
    await postNotificationPushMemberRequest(
      uid,
      token,
      Number(contractCompanyId),
      email ?? "",
      Number(acceptPermission)
    );
    localStorage.setItem(
      LIB_STORAGE_PUSH_NOTIFICATION_KEY,
      JSON.stringify({ acceptPermission: acceptPermission })
    );
    const item = {
      showLater: false,
    };
    localStorage.setItem(
      LIB_STORAGE_PUSH_NOTIFICATION_SHOW_LATER_KEY,
      JSON.stringify(item)
    );
    setLoading(false);
    onClose();
    handleClosePopup();
  };

  return (
    <Modal
      open={opened}
      onClose={handleClose}
      className={STLoginForm.install_modal_container}
      style={{
        maxWidth: isMobile ? 400 : 600,
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ position: "relative" }}>
        <div
          className={`${STLoginForm.install_container} ${classnames.install_container}`}
          style={{ maxWidth: isMobile ? 400 : 600 }}
        >
          <div className={classnames.title}>
            <NotificationsNoneIcon
              className={classnames.iconRequestPermission}
            />
            大事な通知を受け取りませんか？
          </div>
          <div className={classnames.content}>
            重要な情報・便利な情報をお知らせします。
            <br />
          </div>
          {!isMobile && (
            <span className={classnames.contentsub}>
              ※PCの場合、次の下記のポップアップが表示されず、デフォルトの設定が表示される可能性がございます。
            </span>
          )}

          <div>
            {!isMobile && (
              <img
                src="/notification_pc.png"
                alt="プッシュ通知"
                className={classnames.notificationImage}
              />
            )}
            {isAndroid && (
              <img
                src="/notification_android.png"
                alt="プッシュ通知"
                className={classnames.notificationImage}
              />
            )}
            {isIOS && (
              <img
                src="/notification_ios.png"
                alt="プッシュ通知"
                className={classnames.notificationImage}
              />
            )}
          </div>
          <div className={classnames.footer}>
            <div className={classnames.footerGroupButton}>
              <Button
                disabled={loading}
                className={`${classnames.nextButton} ${classnames.button}`}
                onClick={handleContinue}
                style={{ opacity: loading ? "0.9" : 1 }}
              >
                {loading && (
                  <CircularProgress
                    color="inherit"
                    size={18}
                    disableShrink
                    style={{ marginRight: 8 }}
                  />
                )}
                次へ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PushNotificationModal;
