import { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useLoginUser } from "../hooks/useLoginUser";

import { initializeApp } from "firebase/app";
import {
  browserSessionPersistence,
  getAuth,
  indexedDBLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { getMessaging, isSupported } from "firebase/messaging";
import FIREBASE from "../config/firebase";
import { postMemberAccessLog } from "../utils/api/postMemberAccessLog";
import { ACCESS_LOG_ACTION } from "../types/TAppAccessLogAction";
import CONSTANTS from "../utils/constants";
import { addHours } from "date-fns";
import { TAppUserSession } from "../types/TAppUserSession";
import { SiteContext } from "../providers/SiteProvider";
import { TApiTrackingRequestBody } from "../api/tracking";
import { postTracking } from "../utils/api/postTrackingRequest";
import { postNotificationDeviceIdRequest } from "../utils/api/postNotificationDevice";
import isPWA from '../utils/checkPWA';
import { getDeviceId } from "../components/organisms/PushNotificationModal";
import { LoginUserContext } from "../providers/LoginUserProvider";
import { NotificationStatus } from '../hooks/useFireBaseCloudMessage';

export const useAuth = () => {
  const history = useHistory();
  const { setLoginUser } = useLoginUser();
  const { uuid, contractCompanyId } = useContext(SiteContext);
  const { loginUser = {} as any } = useContext(LoginUserContext);
  const { email = '' } = loginUser ?? {};
  const isUsingPwa = isPWA();
  const deviceId = getDeviceId();
  const removeUserSession = () => {
    localStorage.removeItem(CONSTANTS.USER_SESSION_KEY);
  };

  const saveUserSession = (save: boolean) => {
    removeUserSession();

    const expire = save ? null : addHours(new Date(), 2).getTime();
    const userSession: TAppUserSession = { isLimited: !save, expire: expire };
    localStorage.setItem(
      CONSTANTS.USER_SESSION_KEY,
      JSON.stringify(userSession)
    );
  };


  const login = useCallback(
    (email: string, password: string, save: boolean, tenantId: string) => {
      auth.tenantId = tenantId;

      return setPersistence(auth, indexedDBLocalPersistence)
        .then((_) => signInWithEmailAndPassword(auth, email, password))
        .then(
          (userCredential) => {
            const user = userCredential.user;
            setLoginUser(user);
            if(isUsingPwa){
              const trackingContent: TApiTrackingRequestBody = {
                uid: user.uid,
                referrer_uid: null,
                session_uuid: uuid,
                url: window.location.href,
                tracking_flg: 1,
                track_event_name: "PWAログイン",
                click_tag: "pwa login",
                referrer: document.referrer,
              };
              postTracking(trackingContent);
            }
            return user.getIdToken();
          },
          (err) => {
            return undefined;
          }
        )
        .then((token: string | undefined) => {
          if (token) {
            postMemberAccessLog(ACCESS_LOG_ACTION.login, document.referrer);
            saveUserSession(save);
            return true;
          }
          return false;
        });
    },
    [setLoginUser]
  );

  const logout = useCallback( async () => {
    signOut(auth).then(() => {
      removeUserSession();
      history.go(0);
    });
  }, []);

  return { login, logout };
};

const app = initializeApp(FIREBASE);
export const messaging = async () => {
  const supported = await isSupported();
  return supported ? getMessaging(app) : null;
};
const auth = getAuth();
