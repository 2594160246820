import { Box, Typography } from "@material-ui/core";

const TabPanel = (props: any) => {
  const { children, value, index } = props;
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box style={{ padding: "14px 8px 0 8px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
