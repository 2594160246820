import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { getAuth } from "firebase/auth";
import { TLikeContentsResponse } from "../../api/likes";

export type TApiLikeContentsResult = {
  result?: TLikeContentsResponse;
};

export const fetchLikeContents = async (
  offset: number,
  limit?: number
): Promise<TApiResult & TApiLikeContentsResult> => {
  const bodyQuery = {
    skip: offset,
    limit: limit,
  };
  const query = {
    query: { ...bodyQuery },
  };

  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  const authHeader = token && { headers: { Authorization: `Bearer ${token}` } };
  return await client()
    .likes.get({ ...query, ...authHeader })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchContents");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};
