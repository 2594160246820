import React, { useContext } from "react";
import STArticleCard from "../../assets/styles/molecules/articleCard.module.scss";
import { SiteContext } from "../../providers/SiteProvider";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@material-ui/icons/BookmarkOutlined";
type Props = {
  bookmarkFlg?: boolean;
};
const BookmarkCard: React.FC<Props> = (props: Props) => {
  const mainColor = useContext(SiteContext).mainColor;
  return (
    <div className={STArticleCard.bookmarkCard}>
      {!props.bookmarkFlg ? (
        <BookmarkBorderOutlinedIcon fontSize="small" color="disabled" />
      ) : (
        <BookmarkOutlinedIcon fontSize="small" style={{ color: mainColor }} />
      )}
    </div>
  );
};

export default BookmarkCard;
