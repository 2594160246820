import { useCallback, useRef } from "react";
import isPWA from "../../utils/checkPWA";
import STInfoArticleDetail from "../../assets/styles/organisms/infoArticleDetail.module.scss";
import { Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

type ImagePopupProps = {
  src: string;
  alt?: string;
  open: boolean;
  onClose: () => void;
};

export const ContentImagePopup = ({
  src,
  alt,
  open,
  onClose,
}: ImagePopupProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const pinchZoomRef = useRef(null);
  const isUsingPwa = isPWA();
  const onUpdate = useCallback(
    ({ x, y, scale }: { x: number; y: number; scale: number }) => {
      if (!imgRef.current) return;
      const value = make3dTransformValue({ x, y, scale });
      imgRef.current.style.transform = value;
    },
    []
  );
  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose} className={STInfoArticleDetail.modal}>
      <>
        <div className={STInfoArticleDetail.modal_header}>
          <button
            onClick={onClose}
            className={STInfoArticleDetail.close_button}
            aria-label="閉じる"
          >
            <CloseIcon fontSize="large" />
          </button>
        </div>
        <div
          className={STInfoArticleDetail.paper}
          onClick={handleBackdropClick}
        >
          <div className={STInfoArticleDetail.zoom_container}>
            {isUsingPwa ? (
              <QuickPinchZoom
                ref={pinchZoomRef}
                onUpdate={onUpdate}
                tapZoomFactor={2}
                maxZoom={5}
                wheelScaleFactor={1000}
              >
                <img
                  ref={imgRef}
                  src={src}
                  alt={alt || "拡大画像"}
                  className={STInfoArticleDetail.image}
                  draggable={false}
                />
              </QuickPinchZoom>
            ) : (
              <img
                src={src}
                alt={alt || "拡大画像"}
                className={STInfoArticleDetail.image}
                draggable={false}
              />
            )}
          </div>
        </div>
      </>
    </Modal>
  );
};
