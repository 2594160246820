import { TAppFormError } from "../../../types/TAppFormError";
import AlartMessages from "../../atoms/AlartMessages";
import STTextFormRow from "../../../assets/styles/molecules/formRow/textFormRow.module.scss";
import { SelectBoxOption } from "../../../types/form/selectBoxOption";
import SelectBox from "../../atoms/form/SelectBox";

type Props<T> = {
  defaultValue: string;
  title?: string;
  name: string;
  options: SelectBoxOption[];
  className: string;
  formKey: T;
  errors: TAppFormError<T>[];
  hundleChange: (value: string) => void;
};

const PullDownFormRow = <T,>(props: Props<T>) => {
  return (
    <div className={STTextFormRow.text_form_row}>
      {props.title && (
        <span>
          <small>{props.title}</small>
        </span>
      )}
      <div>
        <SelectBox
          className={props.className}
          name={props.name}
          options={props.options}
          defaultValue={props.defaultValue}
          onChange={(input) => props.hundleChange(input)}
          key={props.name}
        />
        {AlartMessages<T>({
          title: props.name,
          errors: props.errors,
          keys: [props.formKey],
        })}
      </div>
    </div>
  );
};

export default PullDownFormRow;
