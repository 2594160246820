import { useState, useEffect } from "react";
import isPWA from "../utils/checkPWA";

export const usePushNotificationPopup = (isPushEnabled: boolean) => {
  const isUsingPwa = isPWA();
  const [opened, setOpened] = useState<boolean>(false);
  const LIB_STORAGE_PUSH_NOTIFICATION_SHOW_LATER_KEY =
    "LibNotificationShowLater";

  const handleClosePopup = () => {
    setOpened(false);
  };

  const handleShowPopup = () => {
    setOpened(true);
  };

  useEffect(() => {
    if (!isUsingPwa) return;

    const storedNotificationShowLater = localStorage.getItem(
      LIB_STORAGE_PUSH_NOTIFICATION_SHOW_LATER_KEY
    );
    if (storedNotificationShowLater) {
      const notificationShowLater = JSON.parse(storedNotificationShowLater);
      const now = new Date();
      if (
        isPushEnabled &&
        notificationShowLater.showLater &&
        notificationShowLater?.expired &&
        now.getTime() > notificationShowLater.expired
      ) {
        setOpened(true);
      }
    } else if (isPushEnabled) {
      setOpened(true);
    }
  }, [isPushEnabled, isUsingPwa]);

  return {
    isPushEnabled,
    opened,
    setOpened,
    handleClosePopup,
    handleShowPopup,
  };
};
