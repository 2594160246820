// Initialize Firebase
import { getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { messaging } from "../hooks/useAuth";

export const NotificationStatus = Object.freeze({
  GRANTED: 'granted',
  DENIED: 'denied',
  DEFAULT: 'default'
});

export const fetchToken = async () => {
  try {
    const fcmMessaging = await messaging();
    let token;
    if (fcmMessaging) {
      const MAX_RETRIES = 10;
      // retry til getting the token
      // reference: https://github.com/firebase/firebase-js-sdk/issues/7575
      for (let i = 0; i < MAX_RETRIES; i++) {
        try {
          token = await getToken(fcmMessaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
          });
        } catch(e) {
          console.log(e);
        }
        if (token) {
          return token;
        }
      }
    }
    return null;
  } catch (err) {
    console.error('Fatal error in fetchToken:', err);
    return null;
  }
};

export const getNotificationPermissionAndToken = async (
  skipPermissionRequest?: boolean
) => {
  const fcmMessaging = await messaging();
  if (!fcmMessaging) {
    return null;
  }
  if (!("Notification" in window)) {
    return null;
  }
  if (Notification.permission === NotificationStatus.GRANTED) {
    return await fetchToken();
  }

  if (
    !skipPermissionRequest &&
    Notification.permission !== NotificationStatus.DENIED
  ) {
    const permission = await Notification.requestPermission();
    if (permission === NotificationStatus.GRANTED) {
      return await fetchToken();
    }
  }
  return null;
};

export const useFireBaseCloudMessage = (loginUser: any) => {
  useEffect(() => {
    if (!loginUser) return;
    const setupListener = async () => {
      const message = await messaging();
      if (!message) return;

      const unsubscribe = onMessage(message, (payload) => {
        if (Notification.permission !== NotificationStatus.GRANTED) return;
        const title = payload?.data?.title ?? "";
        const notification = {
          body: payload?.data?.body,
          data: { link: payload?.data?.link },
        };
        navigator.serviceWorker.ready.then(function (registration) {
          setTimeout(() => {
            registration?.showNotification(title, notification);
          }, 100);
        });
      });
      return unsubscribe;
    };
    let unsubscribe: any = null;
    setupListener();
    return () => unsubscribe?.();
  }, [JSON.stringify(loginUser)]);

  return null;
};
