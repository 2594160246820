import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import STUtils from "../../assets/styles/utility.module.scss";
import { routes } from "../../router/Router";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { TAppInfoArticle } from "../../types/TAppInfoArticle";
import { fetchContentDetail } from "../../utils/api/fetchContentDetail";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { getSubdomain } from "../../utils/getSubdomain";
import Ogp from "../atoms/Ogp";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import InfoArticleDetail from "../organisms/InfoArticleDetail";
import BaseHead from "../templates/BaseHead";

const Information: React.FC = () => {
  const urlParams = useParams<{ id: string }>();
  const subdomain = getSubdomain(window.location.hostname);
  const history = useHistory();
  const auth = getAuth();

  const [infoArticle, setInfoArticle] = useState<TAppInfoArticle>();
  const [isLikeClicked, setIsLikeClicked] = useState<boolean>(true);
  const [isBookmarkClicked, setIsBookmarkClicked] = useState<boolean>(false);

  useEffect(() => {
    fetchContentDetail(urlParams.id).then((res) => {
      if (!res.result || !res.isSuccess) return history.push(routes.error.path);
      const eyeCatchImaegeUrl =
        res.result.img_url !== null
          ? res.result.img_url !== ""
            ? res.result.img_url
            : undefined
          : undefined;
      const newInfoArticle = {
        id: String(res.result.id),
        uid: auth.currentUser?.uid ? auth.currentUser?.uid : null,
        prevContentId: res.result.prev_content_id,
        nextContentId: res.result.next_content_id,
        prevContentTitle: res.result.prev_content_title,
        nextContentTitle: res.result.next_content_title,
        prevContentTypeId: res.result.prev_content_content_type_id,
        nextContentTypeId: res.result.next_content_content_type_id,
        title: res.result.title,
        content: res.result.content,
        eyeCatchImaegeUrl: eyeCatchImaegeUrl,
        postDate: res.result.post_date,
        categoryName: res.result.category_name,
        categoryId: res.result.category_id,
        likeCount: res.result.like_count,
      };
      setInfoArticle(newInfoArticle);
      setIsLikeClicked(res.result.like_flg);
      setIsBookmarkClicked(res.result.bookmark_flg);
      postMemberAccessLog(ACCESS_LOG_ACTION.contentDetail, document.referrer);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.id]);

  if (!infoArticle) return <></>;
  return (
    <BaseHead
      hasBackButton={true}
      title={infoArticle.title}
      login={true}
      subdomain={subdomain}
      hasNavigationBar={true}
    >
      <Ogp
        path={routes.informationDetail.path(infoArticle.id)}
        imageUrl={infoArticle.eyeCatchImaegeUrl}
      />
      <section className={STUtils.container_large}>
        <InfoArticleDetail
          infoArticle={infoArticle}
          isLikeClicked={isLikeClicked}
          isBookmarkClicked={isBookmarkClicked}
          setInfoArticle={setInfoArticle}
          setIsLikeClicked={setIsLikeClicked}
          setIsBookmarkClicked={setIsBookmarkClicked}
        />
        <div style={{ marginBottom: "24px" }}>
          <BottomNavigationBar />
        </div>
      </section>
    </BaseHead>
  );
};

export default Information;
