import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { TApiTrackingRequestBody } from "../../api/tracking";
import STInfoArticleDetail from "../../assets/styles/organisms/infoArticleDetail.module.scss";
import "../../resources/CKEEditor.css";
import { routes } from "../../router/Router";
import { TAppInfoArticle } from "../../types/TAppInfoArticle";
import { postLike } from "../../utils/api/postLike";
import { postTracking } from "../../utils/api/postTrackingRequest";
import getFormattedDatetime from "../../utils/getFormattedDatetime";
import GoodButton from "../GoodButton";
import { contentCreateHtml } from "../JsxParse";
import Image from "../atoms/Image";
import useMultipleClickPreventer from "../molecules/UseMultipleClickPreventer";
import BookmarkButton from "../BookmarkButton";
import ShareButton from "../ShareButton";
import { postBookmark } from "../../utils/api/postBookmark";
import { ContentNavigation } from "../molecules/ContentNavigation";

type Props = {
  infoArticle: TAppInfoArticle;
  isLikeClicked: boolean;
  isBookmarkClicked: boolean;
  isPreview?: boolean;
  setInfoArticle: (newInfoArticle: TAppInfoArticle) => void;
  setIsLikeClicked: (newIsClicked: boolean) => void;
  setIsBookmarkClicked: (newIsClicked: boolean) => void;
};

const InfoArticleDetail: React.FC<Props> = ({
  infoArticle,
  isLikeClicked,
  isBookmarkClicked,
  isPreview = false,
  setInfoArticle,
  setIsLikeClicked,
  setIsBookmarkClicked,
}: Props) => {
  const history = useHistory();

  const hundleGoodButtonClick = useMultipleClickPreventer(
    async (flg: boolean) => {
      if (isPreview) return;
      if (!infoArticle) return history.push(routes.error.path);
      return postLike(Number(infoArticle.id), flg).then((res) => {
        if (!res.isSuccess) return history.push(routes.error.path);
        setIsLikeClicked(flg);
        const newLikeCount = flg
          ? infoArticle.likeCount + 1
          : infoArticle.likeCount - 1;
        const newInfoArticle = {
          ...infoArticle,
          ...{ likeCount: newLikeCount },
        };
        setInfoArticle(newInfoArticle);
      });
    }
  );
  const hundleBookmarkButtonClick = useMultipleClickPreventer(
    async (flg: boolean) => {
      if (isPreview) return;
      if (!infoArticle) return history.push(routes.error.path);
      return postBookmark(Number(infoArticle.id), flg).then((res) => {
        if (!res.isSuccess) return history.push(routes.error.path);
        setIsBookmarkClicked(flg);
      });
    }
  );
  const [cookies, setCookie] = useCookies(["ambLpView"]);
  const [uuid, setUuid] = useState(cookies.ambLpView ? cookies.ambLpView : "");
  const [load, setLoad] = useState(false);

  const handleShareUrl = async () => {
    const shareData = {
      title: infoArticle.title,
      url: window.location.href,
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      infoArticle.content.length > 0 &&
      infoArticle.content.indexOf("https://www.instagram.com/") > 0
    ) {
      const script = document.createElement("script");
      script.type = "text/javascript";

      var attr = document.createAttribute("src");
      attr.value = "//www.instagram.com/embed.js";
      script.setAttributeNode(attr);

      const head = document.getElementsByTagName("head")[0];
      head.appendChild(script);
    }
    const date = new Date();
    const str = date
      .toISOString() //2022-02-05T21:00:00.000Z
      .replace(/[^0-9]/g, ""); //20220205210000000
    const chars = "abcdefghijklmnopqrstuvwxyz";
    let rdmStr = "";
    for (var i = 0; i < 8; i++) {
      rdmStr += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    if (!uuid) {
      setCookie("ambLpView", `${str}-${rdmStr}`, {
        maxAge: 60 * 60 * 24 * 400,
        path: "/",
      });
      setUuid(`${str}-${rdmStr}`);
    } else {
      setCookie("ambLpView", uuid, { maxAge: 60 * 60 * 24 * 400, path: "/" });
    }
    setLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (load) {
      const trackingView: TApiTrackingRequestBody = {
        uid: infoArticle.uid ? infoArticle.uid : null,
        referrer_uid: null,
        session_uuid: uuid,
        url: window.location.href,
        tracking_flg: 2,
        track_event_name: "",
        click_tag: "",
        referrer: document.referrer,
      };
      postTracking(trackingView).then((res) => {
        if (!res.isSuccess) return history.push(routes.error.path);
      });
      setLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  const clickActions = (shareUrl: string) => {
    //計測
    const trackingContent: TApiTrackingRequestBody = {
      uid: infoArticle.uid ? infoArticle.uid : null,
      referrer_uid: null,
      session_uuid: uuid,
      url: window.location.href,
      tracking_flg: 1,
      track_event_name: "シェアボタンクリック",
      click_tag: "click",
      referrer: document.referrer,
      share_url: shareUrl,
    };
    postTracking(trackingContent).then((res) => {
      if (!res.isSuccess) return history.push(routes.error.path);
    });
  };
  if (!infoArticle) return <></>;
  return (
    <section className={STInfoArticleDetail.info_article_detail_new}>
      <h1 className={STInfoArticleDetail.info_article_detail_title}>
        {infoArticle.title}
      </h1>
      {infoArticle.eyeCatchImaegeUrl ? (
        <div className={STInfoArticleDetail.info_article_detail_eyecatch}>
          <Image
            className={STInfoArticleDetail.info_article_detail_eyecatch_img}
            imageUrl={infoArticle.eyeCatchImaegeUrl}
            alt="eyecatch"
            shownDefault={true}
          />
        </div>
      ) : (
        <></>
      )}

      <div className={STInfoArticleDetail.info_article_detail_meta}>
        <p>{getFormattedDatetime(infoArticle.postDate, "YYYY/MM/DD")}</p>
        <Link
          to={`${routes.informations.path}?category=${infoArticle.categoryId}`}
          className={STInfoArticleDetail.category}
        >
          {infoArticle.categoryName}
        </Link>
      </div>
      {infoArticle.content.indexOf("{{シェアボタン", 0) > 0 ||
      infoArticle.content.includes("<img") ? (
        <div className="ck-content">
          {contentCreateHtml(
            infoArticle.content,
            infoArticle.uid ? infoArticle.uid : "",
            clickActions
          )}
        </div>
      ) : (
        <div
          className="ck-content"
          dangerouslySetInnerHTML={{ __html: infoArticle.content }}
        />
      )}
      <div className={STInfoArticleDetail.action}>
        <ShareButton hundleClick={handleShareUrl} />
        <BookmarkButton
          isClicked={isBookmarkClicked}
          hundleClick={hundleBookmarkButtonClick}
        />
        <GoodButton
          isClicked={isLikeClicked}
          count={infoArticle.likeCount}
          hundleClick={hundleGoodButtonClick}
        />
      </div>
      <div className={STInfoArticleDetail.navigation}>
        <ContentNavigation content={infoArticle} />
      </div>
    </section>
  );
};
export default InfoArticleDetail;
