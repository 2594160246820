import { AxiosError } from "axios";
import { getAuth } from "firebase/auth";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { TAppFormError } from "../../types/TAppFormError";
import { TAppPointRequestFormItem } from "../../types/TAppPointRequestFromItem";
import getErrorProperty from "./config/getErrorProperty";
import { PoinAnswer } from "../../api/point/request";

export type TApiPointRequestValidateErrors = {
  validateErrors: TAppFormError<TAppPointRequestFormItem>[];
};

const tokenNotFoundWithValidateErrors: Promise<
  TApiResult & TApiPointRequestValidateErrors
> = new Promise((resolve, reject) =>
  resolve({
    isSuccess: false,
    status: 401,
    message: "tokenNotFound",
    validateErrors: [],
  })
);

export const postPointRequest = async (
  id: number,
  actionMonth: string,
  answers: PoinAnswer[],
): Promise<TApiResult & TApiPointRequestValidateErrors> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token) return tokenNotFoundWithValidateErrors;
  return await client()
    .point.request.post({
      body: {
        id: id,
        action_month: actionMonth,
        answers,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        validateErrors: [],
      };
    })
    .catch((e: AxiosError) => {
      const { status, message, errorMessages } = getErrorProperty(
        e,
        "postPointRequest"
      );
      if (!errorMessages) {
        return {
          isSuccess: false,
          status: status,
          message: message,
          validateErrors: [],
        };
      }

      const validateErrors: TAppFormError<TAppPointRequestFormItem>[] =
        errorMessages.details.map((d) => {
          return {
            key: d.key as TAppPointRequestFormItem,
            messages: d.values,
          };
        });

      return {
        isSuccess: false,
        status: status,
        message: "",
        validateErrors: validateErrors,
      };
    });
};
