import { Grid } from "@material-ui/core";
import STMyList from "../../assets/styles/organisms/myList.module.scss";
import STInfoArticleCardList from "../../assets/styles/organisms/infoArticleCardList.module.scss";
import SpContentCard from "./SpContentCard";
import ArticleCard from "./ArticleCard";
import Pagenation from "./AppPagenation";
import { routes } from "../../router/Router";
import { mylistContentSummary } from "../../types/mylistContentSummary";
import TPager from "../../types/TAppPager";
import { useEffect, useState } from "react";

interface MylistContentListProps {
  contents: mylistContentSummary[];
  spFlag: boolean;
  pager: TPager;
  noContentText: string;
}
const MylistContentList: React.FC<MylistContentListProps> = (props) => {
  const { contents, spFlag, pager, noContentText } = props;
  const [text, setText] = useState<string>("");

  useEffect(() => {
    setTimeout(() => {
      setText(noContentText);
    }, 100);
  }, [noContentText]);

  return (
    <>
      <Grid className={STMyList.info_article_list_article_list_inner}>
        {contents.length === 0 ? (
          <div className={STMyList.none}>{text}</div>
        ) : (
          contents.map((content) => {
            const CommonCardProps = {
              title: content.title,
              contentTypeId: content.contentTypeId,
              linkUrl: content.linkUrl,
              createdAt: content.createdAt,
              goodCount: content.goodCount,
              thumbnailUrl: content.thumbnailUrl,
              thumbnailAlt: content.thumbnailAlt,
              className: `${STInfoArticleCardList.info_article_card_list_article}`,
              likeFlg: content.likeFlg,
              bookmarkFlg: content.bookmarkFlg,
            };

            return spFlag ? (
              <SpContentCard {...CommonCardProps} />
            ) : (
              <ArticleCard {...CommonCardProps} />
            );
          })
        )}
      </Grid>
      {!spFlag && contents.length ? (
        <Pagenation
          className=""
          urlPath={routes.mylist.path}
          total={pager.total}
          start={1}
          end={pager.max}
          current={pager.current || 1}
        />
      ) : null}
    </>
  );
};

export default MylistContentList;
