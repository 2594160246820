import { useEffect, useState } from "react";
import { TPopupSettingResponse } from "../../api/popup_setting";
import STUtils from "../../assets/styles/utility.module.scss";
import useAutomaticPopup from "../../hooks/useAutomaticPopup";
import useModal from "../../hooks/useModal";
import useSaveHistoryToLocalStorage from "../../hooks/useSaveHistoryToLocalStorage";
import { routes } from "../../router/Router";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { getSubdomain } from "../../utils/getSubdomain";
import isPWA from "../../utils/checkPWA";
import AddToHomeScreen from "../molecules/AddToHomeScreen";
import CategoryCardList from "../organisms/CategoryCardList";
import MemberInfoCardNew from "../organisms/MemberInforCardNew";
import MenuList from "../organisms/MenuList";
import News from "../organisms/News";
import PWAInstallationModal from "../organisms/PWAInstallationModal";
import PushNotificationModal from "../organisms/PushNotificationModal";
import Popup from "../organisms/Popup";
import RecommendContentsList from "../organisms/RecommendContentsList";
import RecommendVideosList from "../organisms/RecommendVideosList";
import BaseHead from "../templates/BaseHead";
import { usePushNotificationPopup } from "../../hooks/usePushNotificationPopup";
import { fetchPushNotificationSetting } from "../../utils/api/fetchPushNotificationSetting";

const Top: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const {
    modalComponent: Modal,
    isOpenClickScratchBanner,
    handleOpenPopup,
    handleClosePopup,
    handleOpenBanner,
    handleCloseBanner,
    isOpen,
  } = useModal(false);

  const { saveInvisibleDate, saveInvisibleUpdatedAt } =
    useSaveHistoryToLocalStorage();

  const {
    shouldDisplayWhenLoaded,
    scratchSetting,
    setScratchSetting,
    popupSetting,
    playedScratch,
    isLoading,
    error,
  } = useAutomaticPopup();

  const [isPushEnabled, setIsPushEnabled] = useState<boolean>(false);

  useEffect(() => {
    postMemberAccessLog(ACCESS_LOG_ACTION.topPage, document.referrer);
    try {
      fetchPushNotificationSetting().then((res) => {
        setIsPushEnabled(res?.result?.push_notification_enabled || false);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const { opened } = usePushNotificationPopup(isPushEnabled);
  const isUsingPwa = isPWA();
  useEffect(() => {
    if (isLoading || (isUsingPwa && opened)) return;
    if (shouldDisplayWhenLoaded || isOpenClickScratchBanner) {
      handleOpenPopup();
    } else {
      handleClosePopup();
    }
  }, [
    isLoading,
    shouldDisplayWhenLoaded,
    isOpenClickScratchBanner,
    handleOpenPopup,
    handleClosePopup,
    isUsingPwa,
    opened,
  ]);

  const onClickScratchBanner = () => {
    handleOpenPopup();
    handleOpenBanner();
  };

  const handleClose = () => {
    if (isUsingPwa && shouldDisplayWhenLoaded) {
      handleOpenPopup();
    }
  };

  return (
    <BaseHead
      title={routes.top.title}
      login={true}
      subdomain={subdomain}
      hasBackground={true}
      hasNavigationBar={true}
    >
      <div className={STUtils.container_large}>
        {!isLoading && !error && (
          <Modal>
            <Popup
              popupSetting={popupSetting! as TPopupSettingResponse}
              scratchSetting={scratchSetting}
              playedScratch={playedScratch}
              isOpenClickScratchBanner={isOpenClickScratchBanner}
              handleClosePopup={handleClosePopup}
              handleCloseBanner={handleCloseBanner}
              saveInvisibleDate={saveInvisibleDate}
              saveInvisibleUpdatedAt={saveInvisibleUpdatedAt}
            />
          </Modal>
        )}
        <RecommendContentsList />
        <div style={{ marginTop: window.innerWidth < 800 ? "-70px" : "-85px" }}>
          <MemberInfoCardNew inHeader={false} />
        </div>
        <div className={STUtils.dn_pc}>
          <AddToHomeScreen />
        </div>
        <MenuList
          scratchSetting={scratchSetting}
          onClickScratchBanner={onClickScratchBanner}
          setScratchSetting={setScratchSetting}
        />
        <News />
        <RecommendVideosList />
        <CategoryCardList />
        {isUsingPwa && (
          <PushNotificationModal
            isPushEnabled={isPushEnabled}
            onClose={handleClose}
          />
        )}
        {!!isOpen && !isLoading && !popupSetting && (
          <PWAInstallationModal closePopup={() => {}} />
        )}
      </div>
    </BaseHead>
  );
};

export default Top;
