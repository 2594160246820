import React, { useContext } from "react";
import STArticleCard from "../../assets/styles/molecules/articleCard.module.scss";
import { SiteContext } from "../../providers/SiteProvider";
type Props = {
  count: number;
  likeFlg: boolean;
};
const LikeCard: React.FC<Props> = (props: Props) => {
  const mainColor = useContext(SiteContext).mainColor;
  return (
    <div className={STArticleCard.likeCard}>
      {!props.likeFlg ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
        >
          <path
            d="M7.00018 2.35286C6.82239 2.35288 6.65797 2.25849 6.56834 2.10496C6.00993 1.14849 4.95877 0.5 3.74722 0.5C1.94393 0.5 0.5 1.92896 0.5 3.66984C0.5 4.25373 0.645504 4.99454 1.08726 5.65087C2.91533 8.36604 5.97738 10.7531 7.02369 11.4925C7.03743 11.5027 7.05708 11.5027 7.07081 11.4925C8.02285 10.7531 11.0846 8.36603 12.913 5.65087C13.3548 4.99453 13.5003 4.25397 13.5003 3.66984C13.5003 1.92896 12.0563 0.5 10.253 0.5C9.04144 0.5 7.99008 1.14853 7.43198 2.10488C7.34237 2.25843 7.17796 2.35285 7.00018 2.35286Z"
            stroke="#999999"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
        >
          <path
            d="M10.253 0C8.85917 0 7.64558 0.746857 7.00013 1.85286C6.35442 0.746857 5.1411 0 3.74722 0C1.67768 0 0 1.64303 0 3.66984C0 4.32868 0.1629 5.17298 0.672462 5.93006C2.55209 8.7218 5.67554 11.1518 6.67915 11.8944C6.8696 12.0354 7.13067 12.0354 7.32112 11.8944C8.32473 11.1518 11.4479 8.7218 13.3278 5.93006C13.8374 5.17298 14.0003 4.32894 14.0003 3.66984C14.0003 1.64303 12.3226 0 10.253 0Z"
            fill={mainColor}
          />
        </svg>
      )}
      <h5>{props.count}</h5>
    </div>
  );
};

export default LikeCard;
