import { Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useContext, useEffect, useMemo, useState } from "react";
import STLoginForm from "../../assets/styles/organisms/loginForm.module.scss";
import PAGE_CONFIG from "../../config/page";
import { useOS } from "../../hooks/useOS";
import { SiteContext } from "../../providers/SiteProvider";
import getImageUrl from "../../utils/getImageUrl";
import { getSubdomain } from "../../utils/getSubdomain";
import isPWA from "../../utils/checkPWA";
import { useIsMobile } from "../../hooks/useIsMobile";

type Props = {
  popupOpen?: boolean;
  closePopup: Function;
};

type InstallStep = {
  stepNumber: number;
  text: string;
  imageSrc: string;
  imageAlt: string;
};

const ANDROID_STEPS: InstallStep[] = [
  {
    stepNumber: 1,
    text: "ヘッダーの3点リーダーをクリック",
    imageSrc: "/chrome-installation-step1.png",
    imageAlt: "install-instruction"
  },
  {
    stepNumber: 2,
    text: "メニューから「ホーム画面に追加」を選択",
    imageSrc: "/chrome-installation-step2.png",
    imageAlt: "add-to-home-screen"
  },
  {
    stepNumber: 3,
    text: "「インストール」をクリック",
    imageSrc: "/chrome-installation-step3.png",
    imageAlt: "install"
  }
];

const InstallStep: React.FC<{ step: InstallStep }> = ({ step }) => (
  <div className={step.stepNumber === 1 ? '' : STLoginForm.install_step_container}>
    <div className={STLoginForm.install_step}>
      <div className={STLoginForm.step_number}>{step.stepNumber}</div>
      <p className={STLoginForm.install_step_text}>{step.text}</p>
    </div>
    <img
      className={STLoginForm.install_image_1}
      src={step.imageSrc}
      alt={step.imageAlt}
    />
  </div>
);

const InstallSteps: React.FC<{ steps: InstallStep[] }> = ({ steps }) => (
  <div className={STLoginForm.install_instruction_container}>
    <div className={STLoginForm.install_instruction}>
      {steps.map((step) => (
        <InstallStep key={step.stepNumber} step={step} />
      ))}
      <div className={STLoginForm.install_step_container}>
        <div className={STLoginForm.install_step}>
          <p className={STLoginForm.done_text}>
            以上で、完了です！<br />
            ホーム画面に追加されたことを確認してください。
          </p>
        </div>
      </div>
    </div>
  </div>
);

const PWAInstallationModal: React.FC<Props> = ({popupOpen, closePopup}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isAndroid, isIOS } = useOS();
  const subdomain = getSubdomain(window.location.hostname);
  const { homePopupDescription, homePupopUpdatedAt, enablesHomePopup } = useContext(SiteContext);
  const isUsingPwa = isPWA();
  const { isSafari } = useIsMobile();
  const STORAGE_INSTALLATION_KEY = "InstallationPopup";

  const appIconImageUrl = getImageUrl(
    subdomain,
    PAGE_CONFIG.IMAGE_URL_PATH.SITE,
    isAndroid ? PAGE_CONFIG.FAVICON : PAGE_CONFIG.APPLE_TOUCH_ICON
  );

  const getIOSSteps = useMemo(
    (): InstallStep[] => [
      {
        stepNumber: 1,
        text: "ブラウザのシェアアイコンをタップ",
        imageSrc: isSafari
          ? "/install-instruction.png"
          : "/chrome-ios-install-instruction.png",
        imageAlt: "install-instruction",
      },
      {
        stepNumber: 2,
        text: "メニューから「ホーム画面に追加」を選択",
        imageSrc: "/add-to-home-screen.png",
        imageAlt: "add-to-home-screen",
      },
      {
        stepNumber: 3,
        text: "「追加」をクリック",
        imageSrc: "/click-to-add.png",
        imageAlt: "click-to-add",
      },
    ],
    [isSafari]
  );

  const handleClose = () => {
    setIsOpen(false);
    const now = new Date();
    const updatedTime = new Date(homePupopUpdatedAt).getTime();
    const lastValue = localStorage.getItem(STORAGE_INSTALLATION_KEY);
    let isInstalled = false;
    if(lastValue) {
      isInstalled = JSON.parse(lastValue).installed;
    }
    const item = {
      installed: isInstalled,
      expired: now.getTime() + 7 * 24 * 60 * 60000,
      homePopupUpdatedTime: updatedTime
    };
    localStorage.setItem(STORAGE_INSTALLATION_KEY, JSON.stringify(item));
  };


  useEffect(() => {
    if (popupOpen) {
      setIsOpen(true);
    } else if (enablesHomePopup && !isUsingPwa && (isIOS || isAndroid)) {
      const itemStr = localStorage.getItem(STORAGE_INSTALLATION_KEY);
      if (itemStr) {
        const item = JSON.parse(itemStr);
        const now = new Date();
        const updatedTime = new Date(homePupopUpdatedAt).getTime();
        if (!item.installed && now.getTime() > item.expired ) {
          setIsOpen(true);
        }
        if (!item.installed && item.expired >= updatedTime && item.homePopupUpdatedTime !== updatedTime) {
          setIsOpen(true);
        }
      } else {
        setIsOpen(true);
      }
    }
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={popupOpen ? () => closePopup() : () => handleClose()}
      className={STLoginForm.install_modal_container}
    >
      <div style={{ position: "relative" }}>
        <div className={STLoginForm.close_button} onClick={popupOpen ? () => {closePopup()} : () => handleClose()}>
          <CloseIcon color="inherit" />
        </div>
        <div className={STLoginForm.install_container}>
          <div className={STLoginForm.install_common}>
            <img
              src={appIconImageUrl}
              alt="favicon.png"
              className={STLoginForm.app_icon}
            />
            <p className={STLoginForm.install_text}>{homePopupDescription}</p>
          </div>
          <InstallSteps steps={isIOS ? getIOSSteps : ANDROID_STEPS} />
        </div>
      </div>
    </Modal>
  );
};

export default PWAInstallationModal;
