import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import STNews from "../../assets/styles/organisms/news.module.scss";
import { SiteContext } from "../../providers/SiteProvider";
import { routes } from "../../router/Router";
import { CONTENT_TYPE } from "../../types/TAppContentType";
import TApiResult from "../../types/api/TApiResult";
import { contentSummary } from "../../types/contentSummary";
import {
  TApiContentsResult,
  fetchContents,
} from "../../utils/api/fetchContents";
import { getSubdomain } from "../../utils/getSubdomain";
import ArticleLink from "../molecules/ArticleLink";

const News: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const [news, setNews] = useState<contentSummary[]>([]);
  const history = useHistory();
  const site = useContext(SiteContext);
  const mainColor = site.mainColor;

  useEffect(() => {
    fetchContents(0, 3, [CONTENT_TYPE.news]).then(
      (res: TApiResult & TApiContentsResult) => {
        if (!res.isSuccess || !res.result)
          return history.push(routes.error.path);
        const newNews: contentSummary[] = res.result.contents.map((res) => {
          return {
            id: res.id,
            title: res.title,
            createdAt: res.post_date,
            linkUrl: `news/${res.id}`,
            imageUrl: res.img_url,
            goodCount: res.like_count,
            categoryId: res.category_id,
            likeFlg: res.like_flg,
            bookmarkFlg: res.bookmark_flg,
          };
        });
        setNews(newNews);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={STNews.news_new}>
      <div className={STNews.news_title_new}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <circle cx="12" cy="12" r="9" stroke={mainColor} stroke-width="6" />
        </svg>
        <h3>お知らせ</h3>
      </div>
      <div className="newsList">
        {news.map((article) => (
          <ArticleLink
            key={article.id}
            articleSummary={article}
            className="top-newsArticle"
            subdomain={subdomain}
            likeFlg={article.likeFlg}
            bookmarkFlg={article.bookmarkFlg}
          />
        ))}
      </div>
      <Link to={"/news"} className={STNews.link_to_all}>
        <h3>もっと見る</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <mask
            id="mask0_202_1896"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
          >
            <rect width="16" height="16" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_202_1896)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.29289 3.29289C5.68342 2.90237 6.31658 2.90237 6.70711 3.29289L10.7071 7.29289C11.0976 7.68342 11.0976 8.31658 10.7071 8.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929L8.58579 8L5.29289 4.70711C4.90237 4.31658 4.90237 3.68342 5.29289 3.29289Z"
              fill="#222222"
            />
          </g>
        </svg>
      </Link>
    </section>
  );
};

export default News;
