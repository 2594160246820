import { useContext, useEffect, useState } from "react";
import { SelectBoxOption } from "../../types/form/selectBoxOption";
import Button from "../atoms/Button";
import Label from "../atoms/form/Label";
import SelectBox from "../atoms/form/SelectBox";
import TextLabel from "../atoms/TextLabel";
import STButton from "../../assets/styles/atoms/button.module.scss";
import STPointRequestCard from "../../assets/styles/organisms/pointRequestCard.module.scss";
import TApiResult from "../../types/api/TApiResult";
import {
  fetchPointRequest,
  TApiPointRequestResult,
} from "../../utils/api/fetchPointRequest";
import {
  postPointRequest,
  TApiPointRequestValidateErrors,
} from "../../utils/api/postPointRequest";
import { useHistory } from "react-router-dom";
import TextFormRow from "../molecules/formRow/TextFormRow";
import {
  POINT_REQUEST_FORM_ITEM,
  TAppPointRequestFormItem,
} from "../../types/TAppPointRequestFromItem";
import { PointRequestForm as TPointRequestForm } from "../../types/form/PointRequestForm";
import { TAppFormError } from "../../types/TAppFormError";
import PullDownFormRow from "../molecules/formRow/PullDownFormRow";
import { routes } from "../../router/Router";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { SiteContext } from "../../providers/SiteProvider";
import useMultipleClickPreventer from "../molecules/UseMultipleClickPreventer";
import ReactMarkdown from "react-markdown";
import { PoinAnswer } from "../../api/point/request";

type FormSelectBox = {
  id: number;
  name: string;
  formType: string;
  label: string;
  options?: SelectBoxOption[];
  defaultValue?: string;
};

type FormSection = {
  method: string;
  title: string;
  questions: FormSelectBox[];
  actionMonth: FormSelectBox;
};

const actionMonthSelectBox: FormSelectBox = {
  id: 2,
  name: "action_month",
  formType: "select",
  label: "いつ頃紹介しましたか？",
  defaultValue: "選択してください",
};

type Props = {
  setIsApply: (value: boolean) => void;
};

const PointRequestCard: React.FC<Props> = (props: Props) => {

  const getCurrentYearMonth = (): string => {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2,"0")}`;};

  const [canApply, setCanApply] = useState(false);
  const [methodOptions, setMethodOptions] = useState<SelectBoxOption[]>([]);
  const [formSections, setFormSections] = useState<FormSection[]>([]);
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [selectedFormSection, setSelectedRequestForm] = useState<FormSection>();
  const history = useHistory();
  const [formErrors, setFormErrors] = useState<
    TAppFormError<TAppPointRequestFormItem>[]
  >([]);
  const initialForm: TPointRequestForm = {
    actionMonth: "",
    answers: [],
  };
  const [form, setForm] = useState<TPointRequestForm>(initialForm);

  useEffect(() => {
    fetchPointRequest().then((res: TApiResult & TApiPointRequestResult) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newMethodOptions: SelectBoxOption[] = res.result.master.map(
        (method) => {
          return { id: method.id, value: `${method.id}`, title: method.name };
        }
      );
      const monthOptions: SelectBoxOption[] = res.result.applyable_month.map(
        (month) => {
          return { id: month.id, value: `${month.month}`, title: month.month };
        }
      );

      const currentYearMonth = getCurrentYearMonth();

      setForm({
        ...initialForm,
        actionMonth: currentYearMonth,
      });

      setMethodOptions(newMethodOptions);
      const newForms: FormSection[] = res.result.master.map((method, index) => {
        return {
          method: `${method.id}`,
          title: method.letter_content ?? '',
          questions: method.point_questions.map((q) => {
            return {
              id: q.id,
              name: q.name,
              label: q.name,
              formType: q.option_type === 2 ? "select" : "text",
              options: q.point_question_options.map((op) => {
                return {
                  id: op.id,
                  value: op.name,
                  title: op.name,
                };
              }),
            };
          }),
          actionMonth: {
            ...actionMonthSelectBox,
            options: monthOptions,
            label: method.timing_name,
          },
        };
      });
      setFormSections(newForms);
      setSelectedMethod(newForms[0].method || '');
      setCanApply(!validate(form));
    });
  }, []);

  useEffect(() => {
    const form = formSections.find(
      (requestForm) => requestForm.method === selectedMethod
    );
    if (!form) return;
    setSelectedRequestForm(form);
  }, [selectedMethod]);


  useEffect(() => {
    if (!selectedFormSection) return;


    const currentYearMonth = getCurrentYearMonth();

    const newForm: TPointRequestForm = {
      actionMonth: currentYearMonth,
      answers: [],
    };

    setForm(newForm);
    setCanApply(!validate(newForm));
  }, [selectedFormSection]);

  const validate = (newForm: TPointRequestForm) => {
    const maxLength = 255;
    let hasError = false;
    if (
      (selectedFormSection?.questions.length ?? 0) !==
      (Object.keys(newForm.answers).length ?? 0)
    ) {
      hasError = true;
    }
    Object.keys(newForm.answers).forEach((v: any, i: number) => {
      if (newForm.answers[v] === "") {
        hasError = true;
      } else if (newForm.answers[v].length > maxLength) {
        setFormErrors([
          {
            key: getPropKey(i),
            messages: ["255文字以下で入力してください"],
          },
        ]);
        hasError = true;
      }
    });

    if (newForm.actionMonth === "") {
      hasError = true;
    } else if ( newForm.actionMonth.length > maxLength) {
      hasError = true;
    }

    return hasError;
  };

  const hundleChange = (id: number, answer: string): void => {
    const newAnswers = { ...form.answers };
    newAnswers[id] = answer;
    const newForm: TPointRequestForm = {
      actionMonth: form.actionMonth,
      answers: newAnswers,
    };
    setForm(newForm);
    setCanApply(!validate(newForm));
  };

  const hundleSubmit = useMultipleClickPreventer<() => Promise<any>>(() => {
    if (!canApply) return Promise.resolve();
    const newAnswers: PoinAnswer[] = [];
    Object.keys(form.answers).forEach((v: any) => {
      newAnswers.push({ id: Number(v), answer: form.answers[v] });
    });
    
    return postPointRequest(
      Number(selectedMethod),
      form.actionMonth,
      newAnswers,
    ).then((res: TApiResult & TApiPointRequestValidateErrors) => {
      if (!res.isSuccess) {
        setFormErrors(res.validateErrors);
      } else {
        const accessLogAction = site.isPoint
          ? ACCESS_LOG_ACTION.pointRequest
          : ACCESS_LOG_ACTION.rewardRequest;
        postMemberAccessLog(accessLogAction, document.referrer);
        props.setIsApply(true);
      }
    });
  });

  const site = useContext(SiteContext);

  const getPropKey = (index: number) => {
    type answerTypes = keyof typeof POINT_REQUEST_FORM_ITEM;
    const answerTypeKeys = Object.keys(POINT_REQUEST_FORM_ITEM) as answerTypes[];
    let formKey: TAppPointRequestFormItem = 'answers_0';
    answerTypeKeys.forEach((v) => {
      if (POINT_REQUEST_FORM_ITEM[v] === 'answers_'+index) {
        formKey = POINT_REQUEST_FORM_ITEM[v];
      }
    });
    return formKey;
  }
  
  return (
    <section className={STPointRequestCard.point_request_card}>
      <h1 className={STPointRequestCard.point_request_card_title}>
        {site.isPoint ? "ポイント申請" : "リワード申請"}
      </h1>
      <div className={`${STPointRequestCard.point_request_card_method}`}>
        <TextLabel
          className={STPointRequestCard.point_request_card_method_title}
          fontSize="14"
          isBold={true}
        >
          {site.isPoint ? "ポイントをためる方法" : "リワードをためる方法"}
        </TextLabel>
        <div className={STPointRequestCard.point_request_card_method_select}>
          <SelectBox
            className={
              STPointRequestCard.point_request_card_method_form_selectbox
            }
            name="method"
            options={methodOptions}
            defaultValue={selectedMethod}
            onChange={setSelectedMethod}
          />
        </div>
      </div>
      {selectedFormSection && (
        <div className={STPointRequestCard.point_request_card_request_form}>
          <ReactMarkdown
            linkTarget={"_blank"}
            className={
              STPointRequestCard.point_request_card_method_letter_content
            }
          >
            {selectedFormSection.title
              .replace(/<br([^>]*)>/g, "\n")
              .replace(/<([^>]+)>/g, "") ?? ""}
          </ReactMarkdown>
          <TextLabel
            className={
              STPointRequestCard.point_request_card_request_form_required_text
            }
            fontSize="12"
            isBold={false}
          >
            ※下記、全て必須入力項目です。
          </TextLabel>
          <div className="form">
            {selectedFormSection.questions.map((q, index) => (
              <div key={q.name} className={STPointRequestCard.form_item}>
                <div className={STPointRequestCard.form_item_label}>
                  <Label className="" for={q.name} value={q.label} />
                </div>
                {q.formType === "text" ? (
                  <div className={STPointRequestCard.form_item}>
                    <TextFormRow<TAppPointRequestFormItem>
                      defaultValue={form.answers[q.id] || ""}
                      className={STPointRequestCard.form_item_text}
                      name={`answers-${q.id}`}
                      formKey={getPropKey(index)}
                      hundleOnChange={(input) => hundleChange(q.id, input)}
                      errors={formErrors}
                      setErrors={setFormErrors}
                    />
                  </div>
                ) : (
                  <PullDownFormRow<TAppPointRequestFormItem>
                    defaultValue={form.answers[q.id]}
                    name={`answers-${q.id}`}
                    className={STPointRequestCard.form_item_selectbox}
                    options={q.options || []}
                    formKey={getPropKey(index)}
                    errors={formErrors}
                    hundleChange={(input) => hundleChange(q.id, input)}
                  />
                )}
              </div>
            ))}
            <div className={STPointRequestCard.form_item}>
              <div className={STPointRequestCard.form_item_label}>
                <Label
                  className=""
                  for={actionMonthSelectBox.name}
                  value={
                    selectedFormSection.actionMonth.label ??
                    "いつ頃実施しましたか？"
                  }
                />
              </div>
              <PullDownFormRow<TAppPointRequestFormItem>
                defaultValue={form.actionMonth}
                name={POINT_REQUEST_FORM_ITEM.actionMonth}
                className={STPointRequestCard.form_item_selectbox}
                options={selectedFormSection.actionMonth.options || []}
                formKey={POINT_REQUEST_FORM_ITEM.actionMonth}
                errors={formErrors}
                hundleChange={(input) => {
                  const newForm: TPointRequestForm = {
                    actionMonth: input,
                    answers: { ...form.answers },
                  };
                  setForm(newForm);
                  setCanApply(!validate(newForm));
                }}
              />
            </div>
            <Button
              className={
                canApply
                  ? `${STPointRequestCard.point_request_card_request_form_request_btn} ${STButton.primary}`
                  : `${STPointRequestCard.point_request_card_request_form_request_btn} ${STButton.disable}`
              }
              clickAction={hundleSubmit}
              text="申請"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default PointRequestCard;
