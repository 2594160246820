import { Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useOS } from "../../hooks/useOS";
import { useMemo } from "react";
import STLoginForm from "../../assets/styles/organisms/loginForm.module.scss";

interface Props {
  onClose: () => void;
}

type NotificationStep = {
  stepNumber: number;
  text: string;
  imageSrc: string;
  imageAlt: string;
};

const InstallStep: React.FC<{ step: NotificationStep }> = ({ step }) => (
  <div
    className={step.stepNumber === 1 ? "" : STLoginForm.install_step_container}
  >
    <div className={STLoginForm.install_step}>
      <div className={STLoginForm.step_number}>{step.stepNumber}</div>
      <p className={STLoginForm.install_step_text}>{step.text}</p>
    </div>
    <img
      className={`${STLoginForm.install_image_1} ${
        step.stepNumber === 1 ? STLoginForm.install_image_step1 : ""
      }`}
      src={step.imageSrc}
      alt={step.imageAlt}
    />
  </div>
);

const InstallSteps: React.FC<{ steps: NotificationStep[] }> = ({ steps }) => (
  <div className={STLoginForm.install_instruction_container}>
    <div className={STLoginForm.install_instruction}>
      {steps.map((step) => (
        <InstallStep key={step.stepNumber} step={step} />
      ))}
      <div className={STLoginForm.install_step_container}>
        <div className={STLoginForm.install_step}>
          <p className={STLoginForm.done_text}>以上で、完了です！</p>
        </div>
      </div>
    </div>
  </div>
);

const PushNotificationSettingModal: React.FC<Props> = ({ onClose }) => {
  const { isAndroid, isIOS } = useOS();

  const getSteps = useMemo(
    (): NotificationStep[] => [
      {
        stepNumber: 1,
        text: "「設定」をクリック",
        imageSrc: isIOS
          ? "/ios-notification-step1.png"
          : "/android-notification-step1.png",
        imageAlt: "settings",
      },
      {
        stepNumber: 2,
        text: "「通知」でアプリの一覧を表示",
        imageSrc: isIOS
          ? "/ios-notification-step2.png"
          : "/android-notification-step2.png",
        imageAlt: "notifications",
      },
      {
        stepNumber: 3,
        text: "設定を変更したいアプリをタップ",
        imageSrc: isIOS
          ? "/ios-notification-step3.png"
          : "/android-notification-step3.png",
        imageAlt: "select-app",
      },
      {
        stepNumber: 4,
        text: "「通知を許可」をタップ",
        imageSrc: isIOS
          ? "/ios-notification-step4.png"
          : "/android-notification-step4.png",
        imageAlt: "allow-notifications",
      },
    ],
    [isIOS, isAndroid]
  );

  return (
    <Modal
      open={true}
      onClose={onClose}
      className={STLoginForm.install_modal_container}
    >
      <div style={{ position: "relative" }}>
        <div className={STLoginForm.close_button} onClick={onClose}>
          <CloseIcon color="inherit" />
        </div>
        <div
          style={{ padding: "12px" }}
          className={STLoginForm.install_container}
        >
          <h3 className={STLoginForm.title}>プッシュ通知設定</h3>
          <p className={STLoginForm.install_text}>
            ※各スマートフォンごとに、 画面が異なる可能性がございます。
          </p>
          <InstallSteps steps={getSteps} />
        </div>
      </div>
    </Modal>
  );
};

export default PushNotificationSettingModal;
