// NOTE: https://qiita.com/saba_can00/items/bdefb28a1873658cf5d9
export const POINT_REQUEST_FORM_ITEM = {
  id: "id",
  actionMonth: "action_month",
  answers_0: 'answers_0',
  answers_1: 'answers_1',
  answers_2: 'answers_2',
  answers_3: 'answers_3',
} as const;

type TypeOfPointRequestFormItem = typeof POINT_REQUEST_FORM_ITEM;
export type TAppPointRequestFormItem =
  TypeOfPointRequestFormItem[keyof TypeOfPointRequestFormItem];