// mui4ではTabsで横並びのアイコンテキストが利用できなかったため作成
const flexIconText = (icon: JSX.Element, text: string) => {
  return (
    <div style={{ display: "flex" }}>
      {icon}
      {text}
    </div>
  );
};

export default flexIconText;
