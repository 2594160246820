import { Link } from "react-router-dom";
import Time from "../atoms/Time";
import STNewsCard from "../../assets/styles/molecules/newsCard.module.scss";
import { format } from "../../types/api/TApiDatetime";
import LikeCard from "./LikeCard";
import BookmarkCard from "./BookmarkCard";

type Props = {
  title: string;
  className: string;
  linkUrl: string;
  thumbnailUrl: string | null;
  thumbnailAlt: string;
  createdAt: string;
  goodCount: number;
  contentTypeId?: number;
  content?: string;
  url?: string;
  isRecommended?: boolean;
  likeFlg: boolean;
  bookmarkFlg: boolean;
};

export const NewsCard: React.FC<Props> = (props: Props) => {
  return (
    <div className="newsList">
      <article className={STNewsCard.news_link}>
        {props.title.length > 54 ? (
          <Link to={props.linkUrl}>{props.title.substr(0, 54) + "…"}</Link>
        ) : (
          <Link to={props.linkUrl}>{props.title}</Link>
        )}
        <div className={STNewsCard.news_time_like_container}>
          <Time
            className={STNewsCard.time}
            time={format(props.createdAt, "yyyy.MM.dd")}
          />
          <div className={STNewsCard.news_actionbox}>
            <BookmarkCard bookmarkFlg={props.bookmarkFlg} />
            <LikeCard count={props.goodCount} likeFlg={props.likeFlg} />
          </div>
        </div>
      </article>
    </div>
  );
};
