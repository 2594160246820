import { useState } from "react";

type ShareButtonProps = {
  clickAction: Function;
  id?: string;
  lineUrl?: string;
  shareUrl?: string;
};

export const LineShareButton = ({
  clickAction,
  lineUrl,
  shareUrl,
}: ShareButtonProps) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <button
      onClick={() => {
        clickAction(shareUrl);
        window.open(lineUrl);
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{
        width: "230px",
        borderRadius: "5px",
        filter: "drop-shadow(0 0 0.2rem green)",
        cursor: "pointer",
        display: "inline-block",
        padding: "5px",
        margin: "0 5px",
      }}
      key="0"
    >
      <img
        src={isHover ? "/wide-default-hover.png" : "/wide-default.png"}
        style={{ verticalAlign: "middle" }}
        alt="LINEで送る"
      />
    </button>
  );
};
