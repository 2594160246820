import STShareButton from "../assets/styles/atoms/shareButton.module.scss";

type Props = {
  hundleClick: () => void;
};

const ShareButton: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={STShareButton.container}
      onClick={() => props.hundleClick()}
    >
      <div className={STShareButton.btn}>
        <div className={STShareButton.icon_container}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="24"
            height="24"
            stroke="#87867c"
            fill="none"
            strokeWidth="38"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M336 192h40a40 40 0 0 1 40 40v192a40 40 0 0 1-40 40H136a40 40 0 0 1-40-40V232a40 40 0 0 1 40-40h40m160-64-80-80-80 80m80 193V48" />
          </svg>
        </div>
      </div>
      <div className={STShareButton.action_label}>シェア</div>
    </div>
  );
};

export default ShareButton;
