import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss"; // core Swiper
import STSwiper from "../../assets/styles/molecules/swiper.module.scss";
import STRecommendContentsList from "../../assets/styles/organisms/recommendContentsList.module.scss";
import { useSwiperSettings } from "../../hooks/useSwiperSettings";
import { SiteContext } from "../../providers/SiteProvider";
import { routes } from "../../router/Router";
import { CONTENT_TYPE } from "../../types/TAppContentType";
import TApiResult from "../../types/api/TApiResult";
import { contentSummary } from "../../types/contentSummary";
import {
  TApiContentsResult,
  fetchContents,
} from "../../utils/api/fetchContents";
import ArticleCard from "../molecules/ArticleCard";

const RecommendContentsList: React.VFC = () => {
  const [informations, setInformations] = useState<contentSummary[]>([]);
  const history = useHistory();
  const backgroundColorNew = useContext(SiteContext).backgroundColor;

  const breakpoints = {
    default: {
      slidesPerView: 3,
      slidesPerGroup: 1,
      centeredSlides: false,
      loopThreshold: 3,
    },
    sp: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
      centeredSlides: true,
      loopThreshold: 1,
    },
  };

  const { shouldLoop, slidesPerView, slidesPerGroup, centeredSlides } =
    useSwiperSettings(informations.length, breakpoints);

  useEffect(() => {
    fetchContents(
      0,
      12,
      [CONTENT_TYPE.information, CONTENT_TYPE.news],
      undefined,
      undefined,
      undefined,
      undefined,
      true
    ).then((res: TApiResult & TApiContentsResult) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newInformations: contentSummary[] = res.result.contents.map(
        (res) => {
          return {
            id: res.id,
            title: res.title,
            createdAt: res.post_date,
            linkUrl: routes.informationDetail.path(`${res.id}`),
            imageUrl: res.img_url,
            goodCount: res.like_count,
            likeFlg: res.like_flg,
            bookmarkFlg: res.bookmark_flg,
            categoryId: res.category_id,
          };
        }
      );
      setInformations(newInformations);
    });
  }, []);

  if (informations.length === 0 || shouldLoop === undefined) return <></>;

  return (
    <section
      className={STRecommendContentsList.root}
      style={{
        background: backgroundColorNew,
      }}
    >
      <div className={STRecommendContentsList.wrap_new}>
        <div className={STRecommendContentsList.swiper}>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            loop={shouldLoop}
            spaceBetween={15}
            slidesPerView={slidesPerView}
            slidesPerGroup={slidesPerGroup}
            centeredSlides={centeredSlides}
            loopFillGroupWithBlank={!shouldLoop}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            pagination={
              shouldLoop
                ? {
                    clickable: true,
                    el: `.${STSwiper.pagination}`,
                    bulletActiveClass: STSwiper.pagination_bullet_active_new,
                    bulletClass: STSwiper.pagination_bullet,
                  }
                : {}
            }
            navigation={
              shouldLoop
                ? {
                    nextEl: `.${STSwiper.button_next}`,
                    prevEl: `.${STSwiper.button_prev}`,
                  }
                : {}
            }
          >
            {informations.map((article) => (
              <SwiperSlide key={`slide-${article.id}`}>
                <ArticleCard
                  key={article.id}
                  title={article.title}
                  className={STRecommendContentsList.recommend_contents_new}
                  linkUrl={article.linkUrl}
                  thumbnailUrl={article.imageUrl ? article.imageUrl : ""}
                  thumbnailAlt={article.title}
                  createdAt={article.createdAt}
                  goodCount={article.goodCount}
                  likeFlg={article.likeFlg}
                  bookmarkFlg={article.bookmarkFlg}
                  isRecommended={true}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={STSwiper.pagination}></div>
        </div>
      </div>
    </section>
  );
};
export default RecommendContentsList;
