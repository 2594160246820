import { getAuth } from "firebase/auth";
import { TPushNotificationSettingResponse } from "../../api/push_notification/settings";
import TApiResult from "../../types/api/TApiResult";
import client from "../../api/client";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";

export type TApiPushNotificationSettingResult = {
  result?: TPushNotificationSettingResponse;
};

export const fetchPushNotificationSetting = async (): Promise<
  TApiResult & TApiPushNotificationSettingResult
> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  return client()
    .notification_settings.get({
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchPushNotificationSetting");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};
