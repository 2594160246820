import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { AXIOS_INSTANCE } from "../../api/client";

export type TCheckSurveyAnsweredResponce = {
  status_code: number;
  values: {
    already_answered: boolean;
  };
};

export const checkSurveyAnswered = async (
  id: string,
  body: any
): Promise<TCheckSurveyAnsweredResponce> => {
  const request = {
    url: `/survey_forms/${id}/check_already_answered`,
    method: "post",
    headers: { "Content-Type": "application/json" } as AxiosRequestHeaders,
    data: body,
  };
  return AXIOS_INSTANCE(request as AxiosRequestConfig<any>).then(
    ({ data }) => data
  );
};
