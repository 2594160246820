import { Link } from "react-router-dom";
import Time from "../atoms/Time";
import STSpNewsCard from "../../assets/styles/molecules/spNewsCard.module.scss";
import { format } from "../../types/api/TApiDatetime";
import LikeCard from "./LikeCard";
import BookmarkCard from "./BookmarkCard";

type Props = {
  title: string;
  className: string;
  linkUrl: string;
  thumbnailUrl?: string | null;
  thumbnailAlt?: string;
  createdAt: string;
  goodCount?: number | null;
  contentTypeId?: number;
  url?: string | null;
  likeFlg: boolean;
  bookmarkFlg: boolean;
};

export const SpNewsCard: React.FC<Props> = (props: Props) => {
  return (
    <article className={STSpNewsCard.news_link}>
      <div className={STSpNewsCard.content_card_title_block}>
        <Link
          className={STSpNewsCard.content_card_content_title}
          to={props.linkUrl}
        >
          {props.title}
        </Link>
      </div>
      <div className={STSpNewsCard.news_time_like_container}>
        <Time
          className={STSpNewsCard.time}
          time={format(props.createdAt, "yyyy.MM.dd")}
        />
        <div className={STSpNewsCard.like_bookmark_container}>
          <BookmarkCard bookmarkFlg={props.bookmarkFlg} />
          <LikeCard count={props.goodCount ?? 0} likeFlg={props.likeFlg} />
        </div>
      </div>
    </article>
  );
};
