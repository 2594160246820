import React, { useState } from "react";
import STText from "../../../assets/styles/atoms/form/text.module.scss";
import STloginForm from "../../../assets/styles/organisms/loginForm.module.scss";

type Props = {
  className: string;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  autoComplete?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

const Password: React.FC<Props> = (props: Props) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };

  return (
    <div className={STloginForm.container}>
      <input
        type={isShown ? "text" : "password"}
        autoComplete={props.autoComplete}
        className={`${STText.text} ${props.className}`}
        aria-label={props.name}
        placeholder={props.placeholder}
        value={props.defaultValue}
        onChange={handleChange}
        style={{ width: "100%" }}
        disabled={props.disabled}
      />
      <div className={STloginForm.icon_conatainer}>
        <i
          className={
            isShown
              ? `${STloginForm.icon} fa fa-eye`
              : `${STloginForm.icon} fa fa-eye-slash`
          }
          onClick={() => setIsShown(!isShown)}
        />
      </div>
    </div>
  );
};

export default Password;
