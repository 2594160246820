import { StylesProvider } from "@material-ui/core/styles";
import STMyList from "../../assets/styles/organisms/myList.module.scss";
import { getSubdomain } from "../../utils/getSubdomain";
import { routes } from "../../router/Router";
import { Box, Card, Tabs, Typography, Tab } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import BaseHead from "../templates/BaseHead";
import FavoriteIcon from "@material-ui/icons/Favorite";
import BookmarkOutlinedIcon from "@material-ui/icons/BookmarkOutlined";
import { SiteContext } from "../../providers/SiteProvider";
import { CheckSpFlag } from "../../hooks/CheckSpFlag";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchBookmarkContents,
  TApiBookmarkContentsResult,
} from "../../utils/api/fetchBookmarkContents";
import TApiResult from "../../types/api/TApiResult";
import { mylistContentSummary } from "../../types/mylistContentSummary";
import {
  fetchLikeContents,
  TApiLikeContentsResult,
} from "../../utils/api/fetchLikeContents";
import TPager from "../../types/TAppPager";
import { mylistPagerFetchFunction } from "../../types/mylistPagerFetchFunction";
import TabPanel from "../TabPanel";
import flexIconText from "../atoms/FlexIconText";
import MylistContentList from "../molecules/MylistContentList";

export const MyList: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const mainColor = useContext(SiteContext).mainColor;
  const spFlag = CheckSpFlag();
  const query = new URLSearchParams(useLocation().search);
  const requestPage = query.get("page") ? Number(query.get("page")) : 1;
  const limitCount = spFlag ? 10 : 12; // 画面幅によって取得件数を変更
  const [pager, setPager] = useState<TPager>(
    new TPager(requestPage, 0, limitCount)
  );
  const [tabValue, setTabValue] = useState(0);
  const [contents, setContents] = useState<mylistContentSummary[]>([]);
  const history = useHistory();
  const [totalContentsCount, setTotalContentsCount] = useState(0);
  const [nextIndex, setNextIndex] = useState(limitCount);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(requestPage);

  // TAB変更。変更時にはpagerをリセット
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === tabValue) return;
    query.set("page", "1");
    history.push({ search: query.toString() });
    setTabValue(newValue);
    setNextIndex(limitCount);
    setCurrentPage(1);
    setTotalContentsCount(0);
    setContents([]);
  };
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const mapContentToSummary = (content: any): mylistContentSummary => ({
    id: content.content_id,
    title: content.title,
    createdAt: content.post_date,
    contentTypeId: content.content_type_id,
    linkUrl:
      content.content_type_id === 5
        ? routes.videoDetail.path(`${content.content_id}`)
        : routes.informationDetail.path(`${content.content_id}`),
    imageUrl: content.img_url,
    goodCount: content.like_count,
    likeFlg: content.like_flg,
    bookmarkFlg: content.bookmark_flg,
    thumbnailAlt: content.img_alt ?? "",
    thumbnailUrl: content.img_url ?? "",
  });
  const fetchMylistContents = async (
    isBookmark: boolean,
    newPage: number,
    offset: number,
    limit: number
  ): Promise<
    TApiResult & { result: TApiBookmarkContentsResult | TApiLikeContentsResult }
  > => {
    return new Promise(async (resolve, reject) => {
      try {
        const fetcher: mylistPagerFetchFunction = isBookmark
          ? fetchBookmarkContents
          : fetchLikeContents;
        await fetcher(offset, limit).then(
          (
            res: TApiResult &
              (TApiBookmarkContentsResult | TApiLikeContentsResult)
          ) => {
            if (!res.isSuccess || !res.result)
              return history.push(routes.error.path);
            const newContents: mylistContentSummary[] =
              res.result.contents.map(mapContentToSummary);
            if (spFlag) {
              setContents((prevContents) => [...prevContents, ...newContents]);
              setCurrentPage(newPage);
              setTotalContentsCount(res.result.total_count);
              if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
                resolve({
                  ...res,
                  result: res.result as
                    | TApiBookmarkContentsResult
                    | TApiLikeContentsResult,
                });
              }
            } else {
              setContents(newContents);
              setTotalContentsCount(res.result.total_count);
              const newPager: TPager = new TPager(
                newPage,
                res.result.total_count,
                limitCount
              );
              setPager(newPager);
            }
            setIsLoading(false);
          }
        );
      } catch (error) {
        console.error("コンテンツ取得エラー:", error);
        history.push(routes.error.path);
        reject(error);
      }
    });
  };
  const loadMore = (entries: IntersectionObserverEntry[]) => {
    if (
      entries[0].isIntersecting &&
      nextIndex < totalContentsCount &&
      !isLoading
    ) {
      setIsLoading(true);
      timerRef.current = setTimeout(() => {
        const newPage = currentPage + 1;
        const offset = (newPage - 1) * limitCount;
        tabValue
          ? fetchMylistContents(false, newPage, offset, limitCount)
          : fetchMylistContents(true, newPage, offset, limitCount);
        setNextIndex((prevIndex) => prevIndex + limitCount);
        setIsLoading(false);
      }, 500);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const observer = new IntersectionObserver(loadMore, {
    rootMargin: "100px",
  });

  useEffect(() => {
    const target = document.getElementById("scrollTarget");
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [totalContentsCount, history, currentPage, observer, tabValue]);

  useEffect(() => {
    const offset = (requestPage - 1) * limitCount;
    tabValue
      ? fetchMylistContents(false, requestPage, offset, limitCount)
      : fetchMylistContents(true, requestPage, offset, limitCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, requestPage]);

  return (
    <StylesProvider injectFirst>
      <BaseHead
        title={routes.mypage.title}
        login
        subdomain={subdomain}
        hasNavigationBar={true}
        hasBackButton={true}
      >
        <Box display="flex" justifyContent="center" mt={4} mx={2}>
          <Card className={STMyList.card}>
            <Typography className={STMyList.card_header}>
              保存済み/いいね
            </Typography>
            <Tabs
              centered
              variant="fullWidth"
              value={tabValue}
              onChange={handleChange}
              className={STMyList.tabs}
              TabIndicatorProps={{
                style: {
                  backgroundColor: tabValue === 1 ? "#ff345c" : mainColor, // カスタム色を設定
                },
              }}
            >
              <Tab
                icon={flexIconText(<BookmarkOutlinedIcon />, "保存済み")}
                style={{
                  color: tabValue === 0 ? mainColor : "gray", // 保存済みタブの文字色を変更
                  backgroundColor: tabValue === 0 ? "#FFF" : "#f0f0f0", // いいねタブに影を追加
                }}
              />
              <Tab
                icon={flexIconText(<FavoriteIcon />, "いいね")}
                style={{
                  color: tabValue === 1 ? "#ff345c" : "gray", // いいねタブの文字色を変更
                  backgroundColor: tabValue === 1 ? "#FFF" : "#f0f0f0", // いいねタブに影を追加
                }}
              />
            </Tabs>
            <TabPanel value={0} index={tabValue}>
              <MylistContentList
                contents={contents}
                spFlag={spFlag}
                pager={pager}
                noContentText="保存済み記事はまだありません"
              />
            </TabPanel>
            <TabPanel value={1} index={tabValue} style={{ overflowY: "auto" }}>
              <MylistContentList
                contents={contents}
                spFlag={spFlag}
                pager={pager}
                noContentText="いいねをした記事はまだありません"
              />
            </TabPanel>
            {spFlag && <div id="scrollTarget" style={{ height: "1px" }}></div>}
          </Card>
        </Box>
        <BottomNavigationBar />
      </BaseHead>
    </StylesProvider>
  );
};
