import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import STCategoryCardList from "../../assets/styles/organisms/categoryCardList.module.scss";
import { SiteContext } from "../../providers/SiteProvider";
import { routes } from "../../router/Router";
import { CONTENT_TYPE } from "../../types/TAppContentType";
import TApiResult from "../../types/api/TApiResult";
import { contentSummary } from "../../types/contentSummary";
import {
  TApiContentsResult,
  fetchContents,
} from "../../utils/api/fetchContents";
import InformationCard from "../molecules/InformationCard";
import BottomNavigationBar from "./BottomNavigationBar";

type Props = {
  activeId: number;
  categoryName: string;
};

const InformationList: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [displayedContents, setDisplayedContents] = useState<
    contentSummary[] | undefined
  >();
  const [informations, setInformations] = useState<
    contentSummary[] | undefined
  >();
  const [notFound, setNotFound] = useState<boolean | undefined>();
  const spFlag = window.innerWidth < 800;
  const contentTypeIds = [CONTENT_TYPE.information, CONTENT_TYPE.video];
  const backGroundColor = useContext(SiteContext).backgroundColor;

  const mapContentToSummary = (content: any): contentSummary => ({
    id: content.id,
    title: content.title,
    createdAt: content.post_date,
    contentTypeId: content.content_type_id,
    linkUrl:
      content.content_type_id === 5
        ? routes.videoDetail.path(`${content.id}`)
        : routes.informationDetail.path(`${content.id}`),
    imageUrl: content.img_url,
    content: content.content,
    goodCount: content.like_count,
    categoryId: content.category_id,
    likeFlg: content.like_flg,
    bookmarkFlg: content.bookmark_flg,
  });

  useEffect(() => {
    fetchContents(
      0,
      undefined,
      contentTypeIds,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    ).then((res: TApiResult & TApiContentsResult) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newContents: contentSummary[] =
        res.result.contents.map(mapContentToSummary);
      setNotFound(!Boolean(res.result.total_count));
      setInformations(newContents);
      if (props.activeId === -1) setDisplayedContents(newContents);
    });
  }, []);

  useEffect(() => {
    if (informations && informations.length > 0) {
      const contents =
        props.activeId !== -1
          ? informations.filter((infor) => infor.categoryId === props.activeId)
          : informations;
      if (contents.length === 0) setNotFound(true);
      else setNotFound(false);
      setDisplayedContents(contents);
    }
  }, [props.activeId]);

  return (
    <div
      style={{ background: backGroundColor, paddingBottom: "24px" }}
      className={STCategoryCardList.information_list_block}
    >
      <div className={STCategoryCardList.information_list_container}>
        {notFound && (
          <p className={STCategoryCardList.information_notfound}>
            該当する結果が存在しませんでした。
          </p>
        )}
        <>
          <div className={STCategoryCardList.information_list_newest}>
            {!spFlag
              ? displayedContents?.map((content, index) => {
                  if (index >= 3) return;
                  return (
                    <InformationCard key={index} {...content} isNewest={true} />
                  );
                })
              : displayedContents &&
                displayedContents.length > 0 && (
                  <InformationCard {...displayedContents[0]} isNewest={true} />
                )}
          </div>
          <div className={STCategoryCardList.information_older}>
            {!spFlag
              ? displayedContents?.map((content, index) => {
                  if (index < 3 || index > 6) return;
                  else
                    return (
                      <InformationCard
                        key={index}
                        {...content}
                        isNewest={false}
                      />
                    );
                })
              : displayedContents?.map((content, index) => {
                  if (index < 1 || index > 4) return;
                  else
                    return (
                      <InformationCard
                        key={index}
                        {...content}
                        isNewest={false}
                      />
                    );
                })}
          </div>
        </>
      </div>
      <Link
        to={`/informations?list=true${
          props.activeId !== -1 ? `&category=${props.activeId}` : ""
        }`}
        className={STCategoryCardList.button_link}
      >
        <h3>
          {props.activeId === -1 ? "お役立ち記事" : props.categoryName}一覧
        </h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <mask
            id="mask0_202_1896"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
          >
            <rect width="16" height="16" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_202_1896)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.29289 3.29289C5.68342 2.90237 6.31658 2.90237 6.70711 3.29289L10.7071 7.29289C11.0976 7.68342 11.0976 8.31658 10.7071 8.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929L8.58579 8L5.29289 4.70711C4.90237 4.31658 4.90237 3.68342 5.29289 3.29289Z"
              fill="#222222"
            />
          </g>
        </svg>
      </Link>
      <div>
        <BottomNavigationBar />
      </div>
    </div>
  );
};

export default InformationList;
