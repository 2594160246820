import React from "react";
import { useHistory } from "react-router-dom";
import STCategoryCardList from "../../assets/styles/organisms/categoryCardList.module.scss";
import Image from "../atoms/Image";
import { format } from "../../types/api/TApiDatetime";
import Time from "../atoms/Time";
import LikeCard from "./LikeCard";
import { routes } from "../../router/Router";
import playButtonImage from "../atoms/play_video_button.png";
import BookmarkCard from "./BookmarkCard";

type Props = {
  id: number;
  title: string;
  createdAt: string;
  linkUrl: string;
  imageUrl: string | null;
  thumbnailAlt?: string;
  goodCount: number;
  contentTypeId?: number;
  content?: string;
  isNewest: boolean;
  likeFlg: boolean;
  bookmarkFlg: boolean;
};

const InformationCard: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  const handleClickInformationCard = () => {
    if (props.contentTypeId === 5) {
      history.push(routes.videoDetail.path(props.id.toString()));
    } else {
      history.push(routes.informationDetail.path(props.id.toString()));
    }
  };

  return (
    <article
      onClick={handleClickInformationCard}
      className={
        props.isNewest
          ? STCategoryCardList.information_card_newest
          : STCategoryCardList.information_card
      }
    >
      <div
        className={
          props.isNewest
            ? STCategoryCardList.information_image_container
            : STCategoryCardList.information_image_container_older
        }
      >
        <div className={STCategoryCardList.information_image_block}>
          {props.contentTypeId === 5 && (
            <div className={STCategoryCardList.overlay}>
              <div className={STCategoryCardList.playButtonContainer}>
                <img
                  src={playButtonImage}
                  alt="再生ボタン"
                  className={STCategoryCardList.playButton}
                />
              </div>
            </div>
          )}
          <Image
            alt={props.thumbnailAlt}
            imageUrl={props.imageUrl || ""}
            className={
              props.isNewest
                ? STCategoryCardList.information_image
                : STCategoryCardList.information_image_new
            }
            shownDefault={!props.thumbnailAlt}
          />
        </div>
        {props.isNewest && (
          <div className={STCategoryCardList.information_newest_text}>
            <h4>新着</h4>
          </div>
        )}
      </div>
      <div
        className={
          props.isNewest
            ? STCategoryCardList.information_content
            : STCategoryCardList.information_content_older
        }
      >
        <div
          className={
            props.isNewest
              ? STCategoryCardList.information_title
              : STCategoryCardList.information_title_old
          }
        >
          <h3>{props.title}</h3>
        </div>
        <div className={STCategoryCardList.information_time_like_container}>
          <Time
            className={STCategoryCardList.information_time}
            time={format(props.createdAt ?? "", "yyyy.MM.dd")}
          />
          {props.contentTypeId !== 5 && (
            <div className={STCategoryCardList.information_actionbox}>
              <BookmarkCard bookmarkFlg={props.bookmarkFlg} />
              <LikeCard count={props.goodCount} likeFlg={props.likeFlg} />
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

export default InformationCard;
