import { AxiosError } from "axios";
import { getAuth } from "firebase/auth";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { TAppFormError } from "../../types/TAppFormError";
import getErrorProperty from "./config/getErrorProperty";

export type TApiPostNotificationMemberRequestValidateErrors = {
  validateErrors: TAppFormError<any>[];
};

const tokenNotFoundWithValidateErrors: Promise<
  TApiResult & TApiPostNotificationMemberRequestValidateErrors
> = new Promise((resolve, reject) =>
  resolve({
    isSuccess: false,
    status: 401,
    message: "tokenNotFound",
    validateErrors: [],
  })
);

export const postNotificationPushMemberRequest = async (
  device_id: string,
  firebaseToken: string,
  contract_company_id: number,
  email: string,
  allow_push_notification: number
): Promise<TApiResult & TApiPostNotificationMemberRequestValidateErrors> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token) {
    return tokenNotFoundWithValidateErrors;
  }

  return await client()
    .notification_push_member.post({
      body: {
        device_id: device_id,
        token: firebaseToken || "",
        email: email ?? "",
        contract_company_id: contract_company_id,
        allow_push_notification: allow_push_notification,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        validateErrors: [],
      };
    })
    .catch((e: AxiosError) => {
      const { status, message, errorMessages } = getErrorProperty(
        e,
        "postNotificationPushMemberRequest"
      );
      if (!errorMessages) {
        return {
          isSuccess: false,
          status: status,
          message: message,
          validateErrors: [],
        };
      }

      const validateErrors: TAppFormError<any>[] = errorMessages.details.map(
        (d) => {
          return {
            key: d.key as any,
            messages: d.values,
          };
        }
      );

      return {
        isSuccess: false,
        status: status,
        message: "",
        validateErrors: validateErrors,
      };
    });
};
