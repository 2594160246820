import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss"; // core Swiper
import STSwiper from "../../assets/styles/molecules/swiper.module.scss";
import STRecommendVideosList from "../../assets/styles/organisms/recommendVideosList.module.scss";
import { useSwiperSettings } from "../../hooks/useSwiperSettings";
import { SiteContext } from "../../providers/SiteProvider";
import { routes } from "../../router/Router";
import { CONTENT_TYPE } from "../../types/TAppContentType";
import TApiResult from "../../types/api/TApiResult";
import { contentSummary } from "../../types/contentSummary";
import {
  TApiContentsResult,
  fetchContents,
} from "../../utils/api/fetchContents";
import VideoCard from "../molecules/VideoCard";

const RecommendVideosList: React.VFC = () => {
  const [recommendVideos, setReccommendVideos] = useState<contentSummary[]>([]);
  const history = useHistory();
  const site = useContext(SiteContext);
  const mainColor = site.mainColor;

  const breakpoints = {
    default: {
      slidesPerView: 5,
      slidesPerGroup: 5,
      centeredSlides: false,
      loopThreshold: 5,
    },
    sp: {
      slidesPerView: window.innerWidth < 400 ? 1.8 : 2,
      slidesPerGroup: 1,
      centeredSlides: false,
      loopThreshold: 2,
    },
  };

  const { shouldLoop, slidesPerView, slidesPerGroup, centeredSlides } =
    useSwiperSettings(recommendVideos.length, breakpoints);

  useEffect(() => {
    fetchContents(
      0,
      12,
      [CONTENT_TYPE.video],
      undefined,
      undefined,
      undefined,
      undefined,
      true
    ).then((res: TApiResult & TApiContentsResult) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newVideo: contentSummary[] = res.result.contents.map((res) => {
        return {
          id: res.id,
          title: res.title,
          createdAt: res.post_date,
          linkUrl: routes.videoDetail.path(`${res.id}`),
          imageUrl: res.img_url,
          content: res.content,
          goodCount: res.like_count,
          categoryId: res.category_id,
          likeFlg: res.like_flg,
          bookmarkFlg: res.bookmark_flg,
        };
      });
      setReccommendVideos(newVideo);
    });
  }, []);

  if (recommendVideos.length === 0 || shouldLoop === undefined) return <></>;

  return (
    <section className={STRecommendVideosList.root_new}>
      <div className={STRecommendVideosList.wrap}>
        <div className={STRecommendVideosList.title_new}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <circle cx="12" cy="12" r="9" stroke={mainColor} stroke-width="6" />
          </svg>
          <h3>おすすめ動画</h3>
        </div>
        <div className={STRecommendVideosList.swiper}>
          <Swiper
            modules={[Navigation, Pagination]}
            loop={shouldLoop}
            spaceBetween={15}
            slidesPerView={slidesPerView}
            slidesPerGroup={slidesPerGroup}
            centeredSlides={centeredSlides}
            loopFillGroupWithBlank={!shouldLoop}
            pagination={
              shouldLoop
                ? {
                    clickable: true,
                    el: `.${STSwiper.pagination_videos}`,
                    bulletActiveClass: STSwiper.pagination_bullet_active_videos,
                    bulletClass: STSwiper.pagination_bullet_videos,
                  }
                : {}
            }
            navigation={
              shouldLoop
                ? {
                    nextEl: `.${STSwiper.button_next_videos_new}`,
                    prevEl: `.${STSwiper.button_prev_videos_new}`,
                  }
                : {}
            }
          >
            {recommendVideos.map((video) => (
              <SwiperSlide key={`slide-${video.id}`}>
                <VideoCard
                  key={video.id}
                  title={video.title}
                  className={STRecommendVideosList.recommend_contents}
                  linkUrl={video.linkUrl}
                  content={video.content ?? ""}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={STSwiper.pagination_videos}></div>
          <div className={STSwiper.button_prev_videos_new}></div>
          <div className={STSwiper.button_next_videos_new}></div>
        </div>
      </div>
      <Link to={"/videos"} className={STRecommendVideosList.button_link}>
        <h3>動画一覧</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <mask
            id="mask0_202_1896"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
          >
            <rect width="16" height="16" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_202_1896)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.29289 3.29289C5.68342 2.90237 6.31658 2.90237 6.70711 3.29289L10.7071 7.29289C11.0976 7.68342 11.0976 8.31658 10.7071 8.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929L8.58579 8L5.29289 4.70711C4.90237 4.31658 4.90237 3.68342 5.29289 3.29289Z"
              fill="#222222"
            />
          </g>
        </svg>
      </Link>
    </section>
  );
};
export default RecommendVideosList;
