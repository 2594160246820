import STBookmarkButton from "../assets/styles/atoms/bookmarkButton.module.scss";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@material-ui/icons/BookmarkOutlined";
import { useContext } from "react";
import { SiteContext } from "../providers/SiteProvider";

type Props = {
  isClicked: boolean;
  hundleClick: (isClicked: boolean) => void;
};

const BookmarkButton: React.FC<Props> = (props: Props) => {
  const mainColor = useContext(SiteContext).mainColor;
  return (
    <div
      className={STBookmarkButton.container}
      onClick={() => props.hundleClick(!props.isClicked)}
    >
      <div className={STBookmarkButton.btn}>
        <div className={STBookmarkButton.icon_container}>
          {props.isClicked ? (
            <BookmarkOutlinedIcon
              style={{ width: "24px", height: "24px", color: mainColor }}
            />
          ) : (
            <BookmarkBorderOutlinedIcon
              style={{
                width: "24px",
                height: "24px",
                color: "#87867c",
              }}
            />
          )}
        </div>
      </div>
      <div className={STBookmarkButton.action_label}>保存</div>
    </div>
  );
};

export default BookmarkButton;
