import React, { useEffect } from "react";
import STVideoDetail from "../../assets/styles/organisms/videoDetail.module.scss";
import STInfoArticleDetail from "../../assets/styles/organisms/infoArticleDetail.module.scss"; 
import setEmbedScript from "../../utils/setEmbedScript";
import { ContentNavigation } from "../molecules/ContentNavigation";

interface VideoProps {
  video: {
    content: string;
    prevContentId?: number;
    prevContentTypeId?: number;
    prevContentTitle?: string;
    nextContentId?: number;
    nextContentTypeId?: number;
    nextContentTitle?: string;
  };
  spFlag: boolean;
  routes?: {
    informationDetail: {
      path: (id: string) => string;
    };
  };
}

const YoutubeEmbed: React.FC<VideoProps> = ({ video, spFlag }) => {
  return (
    <div className={STVideoDetail.videoContainer}>
      <div
        className={STVideoDetail.youtube_embed_container}
        dangerouslySetInnerHTML={{ __html: video.content }}
        style={{
          maxWidth: spFlag ? "800px" : "none",
          width: "100%",
          height: "100%",
          position: "relative",
          margin: "0 auto",
          overflow: "hidden",
        }}
      ></div>
    </div>
  );
};

const TikTokEmbed: React.FC<VideoProps> = ({ video }) => {
  useEffect(() => {
    setEmbedScript("TikTok", video.content);
  }, [video.content]);

  return (
    <div className={STVideoDetail.videoContainer}>
      <div
        className={STVideoDetail.videoContent}
        style={{ backgroundColor: "#fef2ef" }}
        dangerouslySetInnerHTML={{ __html: video.content }}
      />
    </div>
  );
};

const InstagramEmbed: React.FC<VideoProps> = ({ video }) => {
  useEffect(() => {
    setEmbedScript("Instagram", video.content);
  }, [video.content]);
  return (
    <div className={STVideoDetail.videoContainer}>
      <div
        className={STVideoDetail.videoContent}
        dangerouslySetInnerHTML={{ __html: video.content }}
      />
    </div>
  );
};

const VideoDetail: React.FC<VideoProps> = ({ video, spFlag }) => {
  let EmbedComponent;

  if (video.content.includes("tiktok.com")) {
    EmbedComponent = TikTokEmbed;
  } else if (video.content.includes("instagram.com")) {
    EmbedComponent = InstagramEmbed;
  } else {
    EmbedComponent = YoutubeEmbed;
  }

  return (
    <>
      <EmbedComponent video={video} spFlag={spFlag} />

      <div className={STInfoArticleDetail.navigation}>
        <ContentNavigation content={video} />
      </div>
    </>
  );
};

export default VideoDetail;
