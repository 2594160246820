import parse, { DOMNode } from "html-react-parser";
import { useState } from "react";
import { LineShareButton } from "./molecules/LineShareButton";
import STInfoArticleDetail from "../assets/styles/organisms/infoArticleDetail.module.scss";
import { ContentImagePopup } from "./molecules/ContentImagePopup";

const ContentParser = ({
  content,
  id,
  clickAction,
}: {
  content: string;
  id: string;
  clickAction: Function;
}) => {
  const [popupImage, setPopupImage] = useState<{
    src: string;
    alt?: string;
  } | null>(null);
  const createOptions = () => {
    const options = {
      replace: (domNode: DOMNode) => {
        if (
          domNode.type === "text" &&
          (domNode as any).data.includes("{{シェアボタン(")
        ) {
          const TextData = (domNode as any).data;
          const tmp = TextData.substring(
            TextData.indexOf("{{シェアボタン(") + 9
          );
          const Url = tmp.substring(0, tmp.indexOf(")}}"));
          const contentUrl = `https://line.me/R/msg/text/${Url ? Url : ""}${
            id ? `?referrer_id=${id}` : ""
          }`;

          const beforeText = TextData.substring(
            0,
            TextData.indexOf(`{{シェアボタン(${Url})}}`)
          );
          const afterText = TextData.substring(TextData.indexOf(")}}") + 3);

          return (
            <>
              {beforeText}
              <LineShareButton
                id={id}
                clickAction={clickAction}
                lineUrl={contentUrl}
                shareUrl={Url}
              />
              {afterText}
            </>
          );
        }

        if (domNode.type === "tag" && (domNode as any).name === "a") {
          const aNode = domNode as any;
          if (aNode.children && aNode.children.length > 0) {
            const hasImageChild = aNode.children.some(
              (child: any) => child.type === "tag" && child.name === "img"
            );

            if (hasImageChild) {
              return domNode;
            }
          }
        }
        if (domNode.type === "tag" && (domNode as any).name === "img") {
          const imgNode = domNode as any;
          const src = imgNode.attribs.src;
          const alt = imgNode.attribs.alt || "";
          const isInsideLink =
            imgNode.parent &&
            imgNode.parent.type === "tag" &&
            imgNode.parent.name === "a";

          if (!isInsideLink) {
            return (
              <img
                src={src}
                alt={alt}
                onClick={() => setPopupImage({ src, alt })}
                className={STInfoArticleDetail.clickable_image}
              />
            );
          }
        }
      },
    };
    return options;
  };
  const htmlJsx = parse(content, createOptions());
  return (
    <>
      {htmlJsx}
      {popupImage && (
        <ContentImagePopup
          src={popupImage.src}
          alt={popupImage.alt}
          open={popupImage !== null}
          onClose={() => setPopupImage(null)}
        />
      )}
    </>
  );
};

export const contentCreateHtml = (
  content: string,
  id: string,
  clickAction: Function
) => {
  return <ContentParser content={content} id={id} clickAction={clickAction} />;
};
