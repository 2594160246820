import { TAppFormError } from "../../../types/TAppFormError";
import TableRow from "../../atoms/TableRow";
import STSinglePasswordRow from "../../../assets/styles/molecules/formTableRow/singlePasswordRow.module.scss";
import AlartMessages from "../../atoms/AlartMessages";
import Password from "../../atoms/form/Password";
import "font-awesome/css/font-awesome.min.css";
import { useState } from "react";
import Text from "../../atoms/form/Text";
import validateFormRow from "../../../utils/form/validateFormRow";
type Props<T> = {
  title: string;
  subTitle?: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  formKey: T;
  errors: TAppFormError<T>[];
  setErrors: (errors: TAppFormError<T>[]) => void;
  hundleOnChange: (value: string) => void;
  isDisplay?: boolean;
  canChange?: boolean;
  maxLength?: number;
  minLength?: number;
  autoComplete?: string;
  pattern?: RegExp;
  patternText?: string;
  matchValue?: string;
  matchMessage?: string;
};

const SinglePasswordRow = <T,>({
  title,
  subTitle,
  placeholder,
  defaultValue,
  required = false,
  formKey,
  errors,
  setErrors,
  hundleOnChange,
  isDisplay = true,
  canChange = true,
  maxLength,
  minLength,
  autoComplete,
  pattern,
  patternText,
  matchValue,
  matchMessage,
}: Props<T>) => {
  const [value, setValue] = useState(defaultValue);

  const hundleChange = (input: string) => {
    setValue(input);
    validateFormRow<T>({
      input,
      errors,
      setErrors,
      formKey,
      maxLength,
      minLength,
      pattern,
      patternText,
      required,
      matchValue,
      matchMessage,
    });
    hundleOnChange(input);
  };

  if (!isDisplay) return <></>;
  return (
    <TableRow title={title} subTitle={subTitle} required={required}>
      <Password
        className={
          canChange ? STSinglePasswordRow.form : STSinglePasswordRow.disable
        }
        name={title}
        defaultValue={value}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={(input) => hundleChange(input)}
        disabled={!canChange}
      />
      {AlartMessages<T>({
        title: title,
        errors: errors,
        keys: [formKey],
      })}
    </TableRow>
  );
};

export default SinglePasswordRow;
