import {
  Box,
  Card,
  Divider,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { Fragment, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { routes } from "../../router/Router";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { getSubdomain } from "../../utils/getSubdomain";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import BaseHead from "../templates/BaseHead";

type MenuProps = {
  menuName: string;
  path?: string;
};

const MyPageItem = ({ menuName, path }: MenuProps) => {
  const classes = useStyles();
  const { logout } = useAuth();
  const theme = useTheme();
  return (
    // <Link>コンポーネントで遷移する場合、CSSレンダリングできないケースがあるため、aタグを使用して遷移する
    // aタグに対してhover時のスタイルを設定するため、onMouseEnterとonMouseLeaveを使用
    <a
      href={path ? path : "#"}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "24px 40px",
        gridGap: "16px",
        color: "inherit",
        ...(theme.breakpoints.down("sm")
          ? {
              padding: "8px 24px",
              justifyContent: "space-between",
            }
          : {}),
      }}
      onClick={() => !path && logout()}
      onMouseEnter={(e) => {
        (e.currentTarget as HTMLElement).style.textDecoration = "underline";
      }}
      onMouseLeave={(e) => {
        (e.currentTarget as HTMLElement).style.textDecoration = "none";
      }}
    >
      <Typography className={classes.menuName}>{menuName}</Typography>
      <ArrowForwardIos style={{ fontSize: 16, color: "#0E0E0E" }} />
    </a>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    maxWidth: 992,
    border: "1px solid #C7C7C7",
    borderRadius: "24px",
    background: "transparent",
    paddingBottom: 12,
    backgroundColor: "rgba(256, 256, 256, 0.9)",
    boxShadow: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000",
    fontSize: 20,
    fontWeight: 700,
    padding: "16px 8px",
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      padding: "24px 40px",
    },
  },
  menuName: {
    fontSize: "18px",
    color: "#0E0E0E",
    cursor: "pointer",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      fontWeight: 400,
    },
  },
  divider: {
    backgroundColor: "#D3D3D3",
  },
}));

const menuItems = [
  { name: "会員情報", path: "/members/edit" },
  { name: "ファイル", path: "/files" },
  { name: "保存済み/いいね", path: "/mylist" },
  { name: "ログアウト" },
];

export const MyPage: React.FC = () => {
  const classes = useStyles();

  const subdomain = getSubdomain(window.location.hostname);

  useEffect(() => {
    postMemberAccessLog(ACCESS_LOG_ACTION.mypage, document.referrer);
  }, []);

  return (
    <BaseHead title={routes.mypage.title} login subdomain={subdomain}>
      <Box display="flex" justifyContent="center" mt={4} mx={2}>
        <Card
          className={classes.card}
          style={{ minHeight: "calc(100vh - 384px)" }}
        >
          <Typography className={classes.cardHeader}>
            マイページメニュー
          </Typography>
          {menuItems.map((item, index) => {
            return (
              <Fragment key={index}>
                <MyPageItem menuName={item.name} path={item.path} />
                <Divider className={classes.divider} />
              </Fragment>
            );
          })}
        </Card>
      </Box>
      <div>
        <BottomNavigationBar />
      </div>
    </BaseHead>
  );
};
