import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { Link } from "react-router-dom";
import { routes } from "../../router/Router";
import STInfoArticleDetail from "../../assets/styles/organisms/infoArticleDetail.module.scss";

export const ContentNavigation = ({ content }: { content: any }) => {
  return (
    <>
      {content.prevContentId && (
        <Link
          to={
            content.prevContentTypeId === 1
              ? `${routes.newsDetail.path(`${content.prevContentId}`)}`
              : content.prevContentTypeId === 2
              ? `${routes.informationDetail.path(`${content.prevContentId}`)}`
              : `${routes.videoDetail.path(`${content.prevContentId}`)}`
          }
          className={STInfoArticleDetail.navigation_prev}
          onClick={() => window.scrollTo(0, 0)}
        >
          <span className={STInfoArticleDetail.navigation_label}>前のコンテンツ</span>
          <div className={STInfoArticleDetail.navigation_label_title}>
            <SlArrowLeft className={STInfoArticleDetail.navigation_arrow} />
            <span className={STInfoArticleDetail.navigation_title}>
              {content.prevContentTitle}
            </span>
          </div>
        </Link>
      )}
      {content.nextContentId && (
        <Link
          to={
            content.nextContentTypeId === 1
              ? `${routes.newsDetail.path(`${content.nextContentId}`)}`
              : content.nextContentTypeId === 2
              ? `${routes.informationDetail.path(`${content.nextContentId}`)}`
              : `${routes.videoDetail.path(`${content.nextContentId}`)}`
          }
          className={STInfoArticleDetail.navigation_next}
          onClick={() => window.scrollTo(0, 0)}
        >
          <span className={STInfoArticleDetail.navigation_label}>次のコンテンツ</span>
          <div className={STInfoArticleDetail.navigation_label_title}>
            <span className={STInfoArticleDetail.navigation_title}>
              {content.nextContentTitle}
            </span>
            <SlArrowRight className={STInfoArticleDetail.navigation_arrow} />
          </div>
        </Link>
      )}
    </>
  );
};
